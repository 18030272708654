// src/pages/AiManager/LoadingSpinner.tsx
import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingSpinner: React.FC = () => (
  <div
    className="d-flex justify-content-center align-items-center"
    style={{ height: "100px" }}
  >
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);

export default LoadingSpinner;
