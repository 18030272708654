import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { observer } from "mobx-react";

import { PageLink, PageTitle } from "../../../layout/core";

import TutorialsBreadCrumbs from "./TutorialsPage";

const tutorialsBreadCrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/tutorials",
    isSeparator: false,
    isActive: false,
  },
];

@observer
class TutorialsWrapper extends React.Component<WrappedComponentProps> {
  render() {
    const { intl } = this.props;
    return (
      <>
        <PageTitle breadcrumbs={tutorialsBreadCrumbs}>
          {intl.formatMessage({ id: "MENU.TUTORIALS" })}
        </PageTitle>

        <TutorialsBreadCrumbs />
      </>
    );
  }
}

const TutorialsWrapperWithIntl = injectIntl(TutorialsWrapper);

export { TutorialsWrapperWithIntl as TutorialsWrapper };
