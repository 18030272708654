import { Component } from "react";

import { fetchAirtableData } from "../../../helpers/AirtableHelper";
import VideoCard from "./TutorialVideoCard";

interface VideoData {
  id: string;
  title: string;
  description: string;
  embed_code: string;
  video: string;
  demo_url: string;
  post_type: string;
  status: string;
}

interface State {
  videoData: VideoData[];
}

class TutorialsPage extends Component<{}, State> {
  state: State = {
    videoData: [],
  };

  async componentDidMount() {
    try {
      const videoData = await fetchAirtableData();
      this.setState({
        videoData: videoData.filter((video) => video.status === "published"),
      });
    } catch (error) {
      console.error("Error fetching Airtable data:", error);
    }
  }

  groupVideosByType(videoData: VideoData[]): Record<string, VideoData[]> {
    return videoData.reduce<Record<string, VideoData[]>>((groups, video) => {
      if (!groups[video.post_type]) {
        groups[video.post_type] = [];
      }
      groups[video.post_type].push(video);
      return groups;
    }, {});
  }

  render() {
    const groupedVideos = this.groupVideosByType(this.state.videoData);

    return (
      <div className="card">
        <div className="card-body p-10 p-lg-15">
          {Object.entries(groupedVideos).map(([postType, videos]) => (
            <div key={postType} className="mb-17">
              <div className="d-flex flex-stack mb-5">
                <h2 className="text-gray-900">
                  {postType + " Tutorial Videos"}
                </h2>
              </div>
              <div className="separator separator-dashed mb-4"></div>
              {/* Grid layout for videos */}
              <div
                className="video-grid"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "20px",
                }}
              >
                {videos.map((video) => (
                  <VideoCard key={video.id} video={video} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default TutorialsPage;
