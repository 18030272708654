import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import ReactGA from "react-ga4";

import { MenuComponent } from "../../../assets/ts/components/MenuComponent";
import { KTIcon, KTSVG, toAbsoluteUrl } from "../../../helpers";
import { AppStatus, CompanyAppStatus } from "../../../helpers/Enums";
import { getAppDepartments, getCompanyAvatarApps } from "../../../helpers/api";

import stores from "../../stores";

interface DeparmentAppSelectionProps {
  onNext: () => void;
  onBack: () => void;
  onAppSelect: (appIds: number[]) => void;
  selectedDepartmentId: number | null;
}

const DeparmentAppSelectionStep: React.FC<DeparmentAppSelectionProps> =
  observer(({ onNext, onBack, onAppSelect, selectedDepartmentId }) => {
    const { companyApps } = stores.companyAppStore;
    const { selectedAppIds, setSelectedApps, isEditMode, editingAvatar } =
      stores.wizardStore;

    const [isNextDisabled, setIsNextDisabled] = useState<boolean>();
    const [searchText, setSearchText] = useState<string>("");
    const [filterType, setFilterType] = useState<string>("all");

    const tableRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      MenuComponent.reinitialization();
    }, []);

    useEffect(() => {
      onAppSelect(selectedAppIds);
      setIsNextDisabled(selectedAppIds.length === 0);
    }, [selectedAppIds, onAppSelect]);

    useEffect(() => {
      if (isEditMode && editingAvatar?.company_avatar_departments) {
        const fetchCompanyAvatarApps = async () => {
          try {
            const companyAvatarApps = await getCompanyAvatarApps(
              Number(editingAvatar.id)
            );

            const companyAppIds = companyAvatarApps.map(
              (app) => app.company_app_id
            );

            const initiallySelectedApps = companyApps
              .filter(
                (companyApp) =>
                  companyAppIds.toString().includes(companyApp.id.toString()) &&
                  stores.companyAppStore.apps.some(
                    (app) => app.id.toString() === companyApp.app_id.toString()
                  )
              )
              .map((companyApp) => companyApp.id);

            setSelectedApps(initiallySelectedApps);
          } catch (error) {
            console.error("Error fetching company avatar apps:", error);
          }
        };

        fetchCompanyAvatarApps();
      } else {
        const fetchAppDepartments = async () => {
          try {
            const fetchAppDepartment = await getAppDepartments();

            const filteredApps = fetchAppDepartment.filter(
              (item) => item.department_id === selectedDepartmentId
            );

            console.log("Filtered Apps:", filteredApps);

            const initiallySelectedApps = stores.companyAppStore.apps.flatMap(
              (app) =>
                companyApps
                  .filter(
                    (companyApp) =>
                      companyApp.app_id.toString() === app.id.toString() &&
                      companyApp.status === CompanyAppStatus.Connected &&
                      filteredApps.some(
                        (filteredApp) =>
                          filteredApp.app_id.toString() ===
                          companyApp.app_id.toString()
                      )
                  )
                  .map((companyApp) => companyApp.id)
            );

            setSelectedApps(initiallySelectedApps);
          } catch (error) {
            console.error("Error fetching app departments:", error);
          }
        };

        fetchAppDepartments();
      }
    }, [
      isEditMode,
      editingAvatar,
      companyApps,
      setSelectedApps,
      selectedDepartmentId,
    ]);

    const isAppConnected = (appId: number) => {
      return companyApps.some(
        (companyApp) =>
          companyApp.app_id.toString() === appId.toString() &&
          companyApp.status === CompanyAppStatus.Connected
      );
    };

    const getAppOpacity = (appId: number) => {
      return isAppConnected(appId) ? 1 : 0.2;
    };

    const filterApps = () => {
      return stores.companyAppStore.apps.filter((app) => {
        if (filterType === "connected") {
          return isAppConnected(app.id);
        } else if (filterType === "selected") {
          return selectedAppIds.some((selectedId) =>
            companyApps.some(
              (companyApp) =>
                companyApp.id.toString() === selectedId.toString() &&
                companyApp.app_id.toString() === app.id.toString()
            )
          );
        } else if (filterType === "not_selected") {
          return (
            !selectedAppIds.some((selectedId) =>
              companyApps.some(
                (companyApp) =>
                  companyApp.id.toString() === selectedId.toString() &&
                  companyApp.app_id.toString() === app.id.toString()
              )
            ) && isAppConnected(app.id)
          );
        }
        return true;
      });
    };

    const filteredApps = filterApps().filter((app) => {
      const appMatches = app.name
        .toLowerCase()
        .includes(searchText.toLowerCase());

      return appMatches;
    });

    const renderAppStatusBadge = (status: AppStatus) => {
      let iconPath = "";
      let badgeClass = "";
      let labelText = "";

      switch (status) {
        case AppStatus.Verified:
          iconPath = "/media/icons/duotune/general/gen048.svg";
          badgeClass = "text-gray-600 text-hover-primary";
          labelText = "Verified";
          break;
        case AppStatus.InReview:
          iconPath = "/media/icons/duotune/general/gen049.svg";
          badgeClass = "text-gray-600 text-hover-warning";
          labelText = "In Review";
          break;
        case AppStatus.Unverified:
          iconPath = "/media/icons/duotune/general/gen050.svg";
          badgeClass = "text-gray-600 text-hover-danger";
          labelText = "Unverified";
          break;
        default:
          return null;
      }

      return (
        <span className={`badge ${badgeClass} fs-8  mb-1 fw-bold p-0 m-0`}>
          <KTSVG path={iconPath} className="svg-icon svg-icon-3 me-1" />
          {labelText}
        </span>
      );
    };

    const handleAppToggle = (appId: number) => {
      const matchedCompanyAppIds = companyApps
        .filter(
          (companyApp) =>
            companyApp.app_id.toString() === appId.toString() &&
            companyApp.status === CompanyAppStatus.Connected
        )
        .map((companyApp) => companyApp.id);

      if (matchedCompanyAppIds.length > 0) {
        const updatedAppIds = selectedAppIds.some((id) =>
          matchedCompanyAppIds.includes(id)
        )
          ? selectedAppIds.filter((id) => !matchedCompanyAppIds.includes(id))
          : [...selectedAppIds, ...matchedCompanyAppIds];

        setSelectedApps(updatedAppIds);

        if (updatedAppIds.length === 1 && tableRef.current) {
          tableRef.current.scrollTop = 0;
        }
      }
    };

    const handleNext = () => {
      if (!isNextDisabled) {
        ReactGA.event({
          category: "avatars_page",
          action: "next_select_avatar_department",
          label: "next_select_avatar_department_button",
        });
        onNext();
      }
    };

    const selectedApps = filteredApps.filter((app) =>
      selectedAppIds.some((selectedId) =>
        companyApps.some(
          (companyApp) =>
            companyApp.id.toString() === selectedId.toString() &&
            companyApp.app_id.toString() === app.id.toString()
        )
      )
    );

    const unselectedApps = filteredApps.filter(
      (app) =>
        !selectedAppIds.some((selectedId) =>
          companyApps.some(
            (companyApp) =>
              companyApp.id.toString() === selectedId.toString() &&
              companyApp.app_id.toString() === app.id.toString()
          )
        )
    );

    return (
      <div style={{ height: "auto", overflowY: "auto", position: "relative" }}>
        <div className="w-100 mt-15 mb-1 d-flex justify-content-between align-items-center">
          <div>
            <h4 className="fw-bold fs-2">Select Department Apps</h4>
            <p className="text-muted fs-6">Select the apps you want to add.</p>
          </div>
          <div
            className="card-toolbar"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            <button
              type="button"
              className="btn btn-light-primary"
              onClick={() =>
                ReactGA.event({
                  category: "avatars_page",
                  action: "filter_button_clicked",
                  label: "filter_button",
                })
              }
            >
              <KTIcon iconName="filter" className="fs-3" />
              Filter
            </button>

            <div
              className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
              data-kt-menu="true"
              data-kt-menu-permanent="true"
            >
              <div className="px-7 py-5">
                <div className="fs-5 text-dark fw-bolder">Filter Options</div>
              </div>
              <div className="px-7 py-5">
                <div className="mb-10">
                  <select
                    className="form-select form-select-solid fw-bolder"
                    data-kt-select2="true"
                    data-placeholder="Select option"
                    onChange={(e) => setFilterType(e.target.value)}
                    value={filterType}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <option value="all">All</option>
                    <option value="connected">Connected</option>
                    <option value="selected">Selected</option>
                    <option value="not_selected">Not Selected</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-start mb-4">
          <KTIcon
            iconName="magnifier"
            className="fs-1 position-absolute px-3"
          />
          <input
            type="text"
            data-kt-user-table-filter="search"
            className="form-control border-0 w-300px ps-12"
            placeholder="Search App..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        <div
          className="table-responsive"
          style={{ height: "500px" }}
          ref={tableRef}
        >
          {selectedApps.map((app) => {
            return (
              <div
                key={app.id}
                className="d-flex align-items-center border-primary border-3 border-dotted bg-light-primary rounded min-w-750px p-3 mb-4"
                style={{ cursor: "pointer" }}
              >
                <div
                  className="d-flex align-items-center flex-grow-1 mx-2"
                  style={{ opacity: getAppOpacity(app.id) }}
                >
                  <div className="symbol symbol-35px me-3">
                    <img src={toAbsoluteUrl(app.logo)} alt={app.name} />
                  </div>

                  <div className="d-flex justify-content-start flex-column">
                    <span className="text-dark fw-bold text-hover-primary fs-5 mb-1 mx-1">
                      {app.name}
                    </span>
                    {renderAppStatusBadge(app.status)}
                  </div>
                </div>

                <div className="d-flex justify-content-end min-w-125px ms-auto">
                  {isAppConnected(app.id) ? (
                    <span className="badge badge-light-success">Connected</span>
                  ) : (
                    <span className="badge badge-light-danger">
                      Not Connected
                    </span>
                  )}
                </div>
                <div
                  className="btn btn-sm btn-icon btn-active-color-danger d-flex  align-items-center justify-content-center mx-2"
                  onClick={() => {
                    if (isAppConnected(app.id)) {
                      handleAppToggle(app.id);
                    }
                  }}
                >
                  <i className="ki-duotone ki-cross-square fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </div>
              </div>
            );
          })}

          {selectedApps.length > 0 && (
            <div className="d-flex justify-content-end align-items-center my-4">
              <hr className="flex-grow-1 border-1 border-gray-300" />
              <span className="px-3 text-muted">
                {selectedApps.length} App selected
              </span>
            </div>
          )}

          {unselectedApps.map((app) => {
            return (
              <div
                key={app.id}
                className="d-flex align-items-center border-gray-300 border-dashed rounded min-w-750px px-7 py-3 mb-4"
                style={{
                  cursor: isAppConnected(app.id) ? "pointer" : "not-allowed",
                }}
                onClick={() => {
                  if (isAppConnected(app.id)) {
                    handleAppToggle(app.id);
                  }
                }}
              >
                <div
                  className="d-flex align-items-center flex-grow-1"
                  style={{ opacity: getAppOpacity(app.id) }}
                >
                  <div className="symbol symbol-35px me-3">
                    <img src={toAbsoluteUrl(app.logo)} alt={app.name} />
                  </div>

                  <div className="d-flex justify-content-start flex-column">
                    <span className="text-dark fw-bold text-hover-primary fs-5 mb-1 mx-1">
                      {app.name}
                    </span>
                    {renderAppStatusBadge(app.status)}
                  </div>
                </div>

                <div className="d-flex justify-content-end min-w-125px ms-auto">
                  {isAppConnected(app.id) ? (
                    <span className="badge badge-light-success">Connected</span>
                  ) : (
                    <span className="badge badge-light-danger">
                      Not Connected
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <div
          className="d-flex justify-content-between"
          style={{ marginTop: "60px" }}
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              onBack();
              ReactGA.event({
                category: "avatars_page",
                action: "back_avatar_info_step",
                label: "back_avatar_info_step_button",
              });
            }}
          >
            <i className="ki-duotone ki-arrow-left fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            Back
          </button>
          <button
            className="btn btn-primary"
            onClick={handleNext}
            disabled={isNextDisabled}
          >
            Next
            <i className="ki-duotone ki-arrow-right fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </button>
        </div>
      </div>
    );
  });

export default DeparmentAppSelectionStep;
