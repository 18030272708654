import React from "react";
import { observer } from "mobx-react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import ReactGA from "react-ga4";

import stores from "../../stores";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { CompanyAvatar } from "../../models/CompanyAvatar";
import { CompanyAvatarStatus, FeatureType } from "../../../helpers/Enums";
import { toast } from "react-toastify";
import i18n from "../../../i18n";

import { AvatarPageLoading } from "./loading/AvatarPageLoading";

type Props = {
  navigate: (path: string) => void;
};

type State = {
  selectedAvatar?: CompanyAvatar;
  isCreateNewAvatar: boolean;
  showDeleteConfirmation: boolean;
  avatarToDelete?: CompanyAvatar;
  isEditMode: boolean;
  deleting: boolean;
  loadingAvatarId?: number;
};

@observer
class AvatarsPage extends React.Component<Props, State> {
  state: State = {
    selectedAvatar: undefined,
    isCreateNewAvatar: false,
    showDeleteConfirmation: false,
    avatarToDelete: undefined,
    isEditMode: false,
    deleting: false,
    loadingAvatarId: undefined,
  };

  handleAvatarSelect = (avatar: CompanyAvatar) => {
    this.setState({ selectedAvatar: avatar });
  };

  handleCreateNewAvatar = async () => {
    ReactGA.event({
      category: "avatars_page",
      action: "create_new_avatar",
      label: "create_new_avatar",
    });
    const hasCreateAvatarPermission =
      await stores.userStore.checkSubscribedFeatureType(
        FeatureType.CreateAvatar
      );

    if (!hasCreateAvatarPermission) {
      toast.error(i18n.ToastMessages.maxAvatarError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    this.setState({ isCreateNewAvatar: true, isEditMode: false });
    stores.wizardStore.resetWizard();
  };

  handleCloseWizard = () => {
    this.setState({ isCreateNewAvatar: false, isEditMode: false });
    stores.wizardStore.resetWizard();
    stores.wizardStore.setIsWizardActive(false);
  };

  deleteAvatar = (avatar: CompanyAvatar) => {
    ReactGA.event({
      category: "avatars_page",
      action: "delete_avatar",
      label: "delete_avatar_button",
    });
    this.setState({ showDeleteConfirmation: true, avatarToDelete: avatar });
  };

  editAvatar = (avatar: CompanyAvatar) => {
    ReactGA.event({
      category: "avatars_page",
      action: "edit_avatar",
      label: "edit_avatar_button",
    });
    stores.wizardStore.populateWizardForEditing(avatar);
    this.setState({ isCreateNewAvatar: true, isEditMode: true });
  };

  confirmDeleteAvatar = async () => {
    const { avatarToDelete } = this.state;

    ReactGA.event({
      category: "avatars_page",
      action: "delete_avatar_modal",
      label: "delete_avatar_modal_button",
    });
    if (avatarToDelete && avatarToDelete.id) {
      this.setState({ deleting: true });
      try {
        const success = await stores.companyAvatarStore.deleteCompanyAvatar(
          avatarToDelete.id
        );
        if (success) {
          await stores.companyAvatarStore.getCompanyAvatars();
          this.setState({
            showDeleteConfirmation: false,
            avatarToDelete: undefined,
            deleting: false,
          });
        } else {
          this.setState({ deleting: false });
        }
      } catch (error) {
        console.error("Failed to delete avatar:", error);
        this.setState({ deleting: false });
      }
    }
  };

  startChatWithAvatar = async (avatar: CompanyAvatar) => {
    ReactGA.event({
      category: "avatars_page",
      action: "start_chat_with_avatar",
      label: "start_chat_with_avatar_button",
    });

    this.setState({ loadingAvatarId: avatar.id });

    stores.companyAvatarStore.selectedCompanyAvatar = avatar;

    if (!stores.companyStore.selectedUserCompany) {
      console.error("No company selected");
      this.setState({ loadingAvatarId: undefined });
      return;
    }

    const documentName = `Chat with ${avatar.name}`;

    try {
      const newDocument = await stores.userDocumentStore.createUserDocument(
        documentName
      );
      stores.userDocumentStore.selectedUserDocument = newDocument;
      stores.userDocumentStore.lastSelectedDocumentId = newDocument.id;

      this.setState({ loadingAvatarId: undefined });

      this.props.navigate("/chat");
    } catch (error) {
      console.error("Failed to create chat document:", error);
      this.setState({ loadingAvatarId: undefined });
    }
  };

  renderCompanyAvatarStatusBadge = (status: CompanyAvatarStatus) => {
    return (
      <>
        {status === CompanyAvatarStatus.Connected ? (
          <span className="badge badge-light-primary fs-8 ms-2 mb-1 fw-bold">
            Connected
          </span>
        ) : status === CompanyAvatarStatus.InProgress ? (
          <span className="badge badge-light-warning fs-8 ms-2 mb-1 fw-bold">
            In Progress
          </span>
        ) : status === CompanyAvatarStatus.Failed ? (
          <span className="badge badge-light-danger fs-8 ms-2 mb-1 fw-bold">
            Failed
          </span>
        ) : (
          <span className="badge badge-light-danger fs-8 ms-2 mb-1 fw-bold">
            Not Connected
          </span>
        )}
      </>
    );
  };

  renderAvatarItem = (avatar: CompanyAvatar, index: number) => {
    const { loadingAvatarId } = this.state;
    const isLoadingThisAvatar = loadingAvatarId === avatar.id;
    const isAnyLoading = loadingAvatarId !== undefined;
    const department = avatar.company_avatar_departments[0]?.department;
    const departmentName = department ? department.name : "Unknown";

    return (
      <div key={index} className="d-flex align-items-center mb-7">
        <div className="symbol symbol-60px me-1">
          <img
            src={toAbsoluteUrl(avatar.logo_url)}
            className="align-self-center"
            alt={avatar.name}
          />
        </div>

        <div className="py-3 px-4 me-1 mb-3">
          <div className="d-flex">
            <span className="fs-6 text-gray-800 fw-bolder">{avatar.name}</span>
            {this.renderCompanyAvatarStatusBadge(avatar.status)}
          </div>

          <div className="fw-bold text-gray-500">{departmentName}</div>

          <div className="text-gray-500">{avatar.description}</div>
        </div>

        <div className="ms-auto d-flex">
          {/* Start Chat Icon */}
          <button
            className="btn btn-icon btn-light-primary me-2"
            disabled={
              avatar.status !== CompanyAvatarStatus.Connected || isAnyLoading
            }
            onClick={() => this.startChatWithAvatar(avatar)}
          >
            {isLoadingThisAvatar ? (
              <Spinner as="span" animation="border" size="sm" />
            ) : (
              <KTSVG
                path="/media/icons/duotune/communication/com012.svg"
                className="svg-icon-3"
              />
            )}
          </button>
          {/* Edit Icon */}
          <button
            className="btn btn-icon btn-light-warning me-2"
            onClick={() => this.editAvatar(avatar)}
            disabled={isAnyLoading}
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
          </button>
          {/* Delete Icon */}
          <button
            className="btn btn-icon btn-light-danger"
            onClick={() => this.deleteAvatar(avatar)}
            disabled={isAnyLoading}
          >
            <KTSVG
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-3"
            />
          </button>
        </div>
      </div>
    );
  };

  render() {
    const {
      isCreateNewAvatar: isCreatNewAvatar,
      showDeleteConfirmation,
      deleting,
      isEditMode,
      loadingAvatarId,
    } = this.state;
    const { isLoading } = stores.companyModelStore;

    if (isLoading) {
      return <AvatarPageLoading />;
    }

    if (deleting) {
      return <AvatarPageLoading />;
    }

    if (isCreatNewAvatar || isEditMode) {
      return <Navigate to={`/create-new-avatar`} />;
    }

    return (
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h3 className="card-title">Manage Avatars</h3>
          <Button
            variant="primary"
            onClick={this.handleCreateNewAvatar}
            disabled={!!loadingAvatarId}
          >
            Create New Avatar
          </Button>
        </div>
        <div className="card-body">
          {stores.companyAvatarStore.companyAvatars.length > 0 ? (
            stores.companyAvatarStore.companyAvatars.map(this.renderAvatarItem)
          ) : (
            <div>You don't have any avatars.</div>
          )}

          <Modal
            show={showDeleteConfirmation}
            onHide={() => this.setState({ showDeleteConfirmation: false })}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Avatar</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this avatar?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ showDeleteConfirmation: false });
                  ReactGA.event({
                    category: "avatars_page",
                    action: "cancel_delete_avatar_modal",
                    label: "cancel_delete_avatar_modal_button",
                  });
                }}
                disabled={!!loadingAvatarId}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  this.confirmDeleteAvatar();
                }}
                disabled={!!loadingAvatarId}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

export default AvatarsPage;
