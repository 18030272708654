import React, { Component } from "react";
import { Link } from "react-router-dom";

import i18n from "../../../../i18n";
import stores from "../../../stores";

export default class Overview extends Component {
  render() {
    return (
      <>
        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
          <div className="card-header cursor-pointer">
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">{i18n.ProfileDetails.title}</h3>
            </div>

            <Link
              to="/crafted/account/settings"
              className="btn btn-primary align-self-center"
            >
              {i18n.ProfileDetails.editProfile}
            </Link>
          </div>

          <div className="card-body p-9">
            <div className="row mb-7">
              <label className="col-lg-4 fw-bold text-muted">
                {i18n.ProfileDetails.fullNameLabel}
              </label>

              <div className="col-lg-8">
                <span className="fw-bolder fs-6 text-dark">
                  {stores.userStore.currentUser.name}
                </span>
              </div>
            </div>

            <div className="row mb-7">
              <label className="col-lg-4 fw-bold text-muted">
                {i18n.ProfileDetails.emailLabel}
              </label>

              <div className="col-lg-8 d-flex align-items-center">
                <span className="fw-bolder fs-6 me-2">
                  {stores.userStore.currentUser.email}
                </span>

                <span
                  className={
                    stores.userStore.currentUser.email_verified === true
                      ? "badge badge-success"
                      : "badge badge-danger"
                  }
                >
                  {stores.userStore.currentUser.email_verified === true
                    ? "Verified"
                    : "Not verified"}
                </span>
              </div>
            </div>

            {/* <div className="row mb-7">
              <label className="col-lg-4 fw-bold text-muted">
                {i18n.ProfileDetails.companyLabel}
              </label>

              <div className="col-lg-8 fv-row">
                <span className="fw-bold fs-6">
                  {stores.companyStore.selectedUserCompany?.name}
                </span>
              </div>
            </div> */}

            <div className="row mb-7">
              <label className="col-lg-4 fw-bold text-muted">
                {i18n.ProfileDetails.contactPhoneLabel}
              </label>

              <div className="col-lg-8 d-flex align-items-center">
                <span className="fw-bolder fs-6 me-2">
                  {stores.userStore.currentUser.phone_number === "undefined" ||
                  undefined
                    ? " "
                    : stores.userStore.currentUser.phone_number}
                </span>

                <span
                  className={
                    stores.userStore.currentUser.phone_number_verified === true
                      ? "badge badge-success"
                      : "badge badge-danger"
                  }
                >
                  {stores.userStore.currentUser.phone_number_verified === true
                    ? "Verified"
                    : "Not verified"}
                </span>
              </div>
            </div>

            {/* <div className="row mb-10">
              <label className="col-lg-4 fw-bold text-muted">
                Allow Changes
              </label>

              <div className="col-lg-8">
                <span className="fw-bold fs-6">Yes</span>
              </div>
            </div> */}
          </div>
        </div>
      </>
    );
  }
}
