import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Row, Col } from "react-bootstrap";

import stores from "../../stores";

import ReportRow from "./ReportRow";
import LoadingSpinner from "./LoadingSpinner";
import AudioPlayer from "./AudioPlayer";
import { getReportSummaryRecord } from "../../../helpers/api";
import SummaryTextModal from "./SummaryTextModal";

const columnStyles: Record<string, { headerBorderColor: string }> = {
  Process: {
    headerBorderColor: "#fdd835",
  },
  Problem: {
    headerBorderColor: "#4caf50",
  },
  Suggestion: {
    headerBorderColor: "#1976d2",
  },
};

const AiManagerPage = observer(() => {
  const [summaryRecordUrl, setSummaryRecordUrl] = useState<string | undefined>(
    undefined
  );

  const [summaryRecordText, setSummaryRecordText] = useState<
    string | undefined
  >(undefined);
  const [showTextModal, setShowTextModal] = useState(false);

  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      stores.companyReportsStore.getCompanyReports();

      const fetchSummaryRecord = async () => {
        if (
          stores.companyStore.selectedUserCompany &&
          stores.companyStorageStore.selectedCompanyStorage
        ) {
          const record = await getReportSummaryRecord(
            stores.companyStore.selectedUserCompany.id,
            stores.companyStorageStore.selectedCompanyStorage.id,
            new Date().toISOString().split("T")[0]
          );

          setSummaryRecordUrl(record.url);
          setSummaryRecordText(record.text);
        }
      };

      fetchSummaryRecord();
    }
  }, []);

  return (
    <div className="container pb-4 pt-0 py-4">
      <div className="d-flex justify-content-end mb-3">
        <div className="audio-section">
          <Row className="mb-2">
            <Col>
              <div className="mb-3 text-muted text-center">
                {summaryRecordUrl === undefined
                  ? "The daily summary record and text are loading..."
                  : summaryRecordUrl === ""
                  ? "The daily summary record or text is not ready yet."
                  : "Would you like to listen to or read the daily summary?"}
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="d-flex align-items-center justify-content-evenly">
              <i
                className={`fas fa-file-alt me-3 ${
                  summaryRecordText
                    ? "text-primary cursor-pointer"
                    : "text-muted"
                }`}
                style={{
                  fontSize: "1.5rem",
                  pointerEvents: summaryRecordText ? "auto" : "none",
                }}
                title={
                  summaryRecordText
                    ? "Read Summary Text"
                    : "Summary text is not available."
                }
                onClick={() => summaryRecordText && setShowTextModal(true)}
              ></i>
              <AudioPlayer src={summaryRecordUrl ? summaryRecordUrl : ""} />
            </Col>
          </Row>
        </div>
      </div>

      <div className="card shadow-sm border-0 rounded">
        <div
          className="card-header d-flex align-items-center justify-content-evenly"
          style={{
            border: "none",
            minHeight: "30px",
            paddingTop: "14px",
          }}
        >
          <Row className="w-100">
            {["Process", "Problem", "Suggestion"].map((title, index) => (
              <Col
                key={title}
                className={index === 0 ? "ps-0" : index === 2 ? "pe-0" : ""}
              >
                <div
                  className="d-flex align-items-center justify-content-start"
                  style={{
                    borderBottom: `2px solid ${columnStyles[title].headerBorderColor}`,
                    paddingBottom: "10px",
                  }}
                >
                  <h5
                    className="mb-0 text-dark fw-bold text-center"
                    style={{
                      fontSize: "1.25rem",
                    }}
                  >
                    {title}
                  </h5>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <SummaryTextModal
          show={showTextModal}
          handleClose={() => setShowTextModal(false)}
          text={summaryRecordText || "No summary text available."}
        />
        <div
          className="card-body justify-content-evenly"
          style={{
            minHeight: "80vh",
            padding: "20px",
          }}
        >
          {stores.companyReportsStore.isLoading ? (
            <LoadingSpinner />
          ) : (
            stores.companyReportsStore.companyReports.map((report) => (
              <Row key={report.id} className="mb-3">
                <ReportRow report={report} />
              </Row>
            ))
          )}
        </div>
      </div>
    </div>
  );
});

export default AiManagerPage;
