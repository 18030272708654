import { gql } from "@apollo/client";
import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyReport } from "../../app/models/CompanyReport";

export default async function updateCompanyReport(
  updatedReport: Partial<CompanyReport>
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation UpdateCompanyReport(
      $id: ID!
      $company_id: Int!
      $company_avatar_id: Int
      $report1: String
      $report2: String
      $report3: String
      $date: DateTime!
    ) {
      updateCompanyReport(
        id: $id
        company_id: $company_id
        company_avatar_id: $company_avatar_id
        report1: $report1
        report2: $report2
        report3: $report3
        date: $date
      ) {
        id
        report1
        report2
        report3
        date
      }
    }
  `;

  try {
    const { data } = await client.mutate({
      mutation,
      variables: {
        id: updatedReport.id,
        company_id: updatedReport.company_id,
        company_avatar_id: updatedReport.company_avatar_id,
        report1: updatedReport.report1,
        report2: updatedReport.report2,
        report3: updatedReport.report3,
        date: updatedReport.date,
      },
    });

    return Boolean(data.updateCompanyReport);
  } catch (error) {
    console.error("Failed to update company report:", error);
    return false;
  }
}
