/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";

export default async function deleteCompanyReport(
  id: number
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation DeleteCompanyReport($id: ID!) {
      deleteCompanyReport(id: $id)
    }
  `;

  try {
    const deleteCompanyReportMutationResult = await client.mutate({
      mutation,
      variables: { id },
    });

    const result = get(
      deleteCompanyReportMutationResult,
      "data.deleteCompanyReport",
      null
    );

    return result !== null && result > 0;
  } catch (error) {
    console.error("Failed to delete company report:", error);
    return false;
  }
}
