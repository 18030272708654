/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { observer } from "mobx-react";

import stores from "../../stores";

type HeaderUserMenuProps = {
  setLink: (link: string) => void;
};

export const HeaderUserMenu: React.FC<HeaderUserMenuProps> = observer(
  ({ setLink }) => {
    const { logout } = useAuth0();

    const handleLogout = () => {
      logout({
        logoutParams: {
          returnTo: process.env.REACT_APP_AUTH0_CALLBACK_URL,
        },
      });

      stores.userStore.logout();
    };

    return (
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
        data-kt-menu="true"
      >
        {/* <Languages /> */}
        {stores.userStore.isCurrentUserAdmin && (
          <>
            <div className="menu-item px-5 my-1">
              <a
                className="menu-link px-5"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_upgrade_plan"
              >
                My Plan
              </a>
            </div>

            <div className="menu-item px-5 my-1">
              <a className="menu-link px-5" onClick={() => setLink("/account")}>
                Account Settings
              </a>
            </div>
          </>
        )}

        <div className="menu-item px-5">
          <a onClick={handleLogout} className="menu-link px-5">
            Sign Out
          </a>
        </div>
      </div>
    );
  }
);
