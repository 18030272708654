import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";

import AccountPage from "./AccountPage";

interface IAccountWrapperProps extends WrappedComponentProps {}

class AccountWrapper extends React.Component<IAccountWrapperProps> {
  render() {
    return (
      <>
        <AccountPage />
      </>
    );
  }
}

const AccountWrapperWithIntl = injectIntl(AccountWrapper);

export { AccountWrapperWithIntl as AccountWrapper };
