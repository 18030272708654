import React, { useState } from "react";
import { PageLink, PageTitle } from "../../../layout/core";

import Overview from "./components/Overview";
import Settings from "./components/Settings";
import { AccountHeader } from "./AccountHeader";

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: "Account",
    path: "/account",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const AccountPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("settings");

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };

  return (
    <div>
      <AccountHeader selectedTab={selectedTab} onTabChange={handleTabChange} />

      <PageTitle breadcrumbs={accountBreadCrumbs}>
        {selectedTab === "settings" ? "Settings" : "Overview"}
      </PageTitle>

      {selectedTab === "settings" ? <Settings /> : <Overview />}
    </div>
  );
};

export default AccountPage;
