/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

interface TutorialVideoCardProps {
  video: {
    id: string;
    title: string;
    description: string;
    embed_code: string;
    demo_url: string;
  };
}

const TutorialVideoCard: React.FC<TutorialVideoCardProps> = ({ video }) => (
  <div className="col">
    <div className="card-xl-stretch mx-3 ">
      <div className="card-body d-flex flex-column justify-content-between p-0 ">
        <div className="d-block bgi-no-repeat bgi-size-cover bgi-position-center card-rounded position-relative min-h-175px mb-5">
          <div dangerouslySetInnerHTML={{ __html: video.embed_code }}></div>
        </div>
      </div>

      <div className="m-0">
        <a
          className="fs-4 text-gray-900 fw-bold text-hover-primary text-gray-900 lh-base"
          target="_blank"
          rel="noopener noreferrer"
          style={{ cursor: "pointer" }}
        >
          {video.title}
        </a>

        <div className="fw-semibold fs-6 my-4 text-muted">
          {video.description}
        </div>
      </div>
    </div>
  </div>
);

export default TutorialVideoCard;
