import React, { useState } from "react";
import { Card, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReportCardModal from "./ReportCardModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { CompanyReport } from "../../models/CompanyReport";
import stores from "../../stores";
import Functions from "../../../helpers/Functions";
import MarkdownPreview from "@uiw/react-markdown-preview";
// import AudioPlayer from "./AudioPlayer";

interface ReportCardProps {
  report: CompanyReport;
  reportKey: "report1" | "report2" | "report3";
}

const reportTitles: Record<ReportCardProps["reportKey"], string> = {
  report1: "Process",
  report2: "Problem",
  report3: "Suggestion",
};

const ReportCard: React.FC<ReportCardProps> = ({ report, reportKey }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  // const [showAudioPlayer, setShowAudioPlayer] = useState(false);

  const handleReadMore = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  // const toggleAudioPlayer = () => setShowAudioPlayer(!showAudioPlayer);

  const handleDeleteConfirm = async () => {
    setDeleteModalOpen(false);
    try {
      const updatedReport = {
        ...report,
        id: Number(report.id),
        [reportKey]: null,
      };

      const isUpdated = await stores.companyReportsStore.updateCompanyReport(
        updatedReport
      );

      if (isUpdated) {
        const { report1, report2, report3 } = updatedReport;
        if (!report1 && !report2 && !report3) {
          await stores.companyReportsStore.deleteCompanyReport(report.id);
        }
      }
    } catch (error) {
      console.error("An error occurred while deleting the report:", error);
    }
  };

  const handleDelete = () => setDeleteModalOpen(true);

  const content = report[reportKey] || "";
  const shortContent =
    content.length > 150 ? `${content.slice(0, 150)}...` : content;

  const createdBy =
    report.company_avatar_id != null && stores.companyAvatarStore.companyAvatars
      ? (() => {
          const avatar = stores.companyAvatarStore.companyAvatars.find(
            (avatar) => Number(avatar.id) === Number(report.company_avatar_id)
          );

          return {
            id: avatar?.id,
            name: avatar?.name || "Unknown Avatar",
            logo:
              avatar?.logo_url || "https://default.logo.url/placeholder.png",
          };
        })()
      : {
          id: undefined,
          name: "The Chief",
          logo: "https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/93bbf41d-9c33-44c7-b6eb-38fe5b9a1300/mid",
        };

  const formattedDate = Functions.formatDate(report.date);

  if (!content || content.trim() === "") {
    return (
      <Card className="m-3 shadow-sm border border-gray-300 rounded">
        <Card.Body
          className="p-4"
          style={{
            border: "1px solid #e2e5ec",
            borderRadius: "4px",
            textAlign: "center",
          }}
        >
          <h6 className="text-muted">This report has been deleted</h6>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="m-3 shadow-sm border border-gray-300 rounded d-flex flex-column">
      <Card.Body className="p-4 flex-grow-1 d-flex flex-column justify-content-between">
        <div className="d-flex justify-content-between align-items-center mb-0">
          <h6 className="mb-0 fw-bold mt-2">
            {reportTitles[reportKey]}{" "}
            <span className="text-muted" style={{ fontSize: "0.85rem" }}>
              {" "}
              ({formattedDate})
            </span>
          </h6>
          <div
            className="fs-5 text-danger cursor-pointer"
            onClick={handleDelete}
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            <i className="fas fa-trash-alt"></i>
          </div>
        </div>
        <Card.Text className="mt-0 mb-0 text-muted py-5">
          <MarkdownPreview
            source={shortContent}
            style={{
              backgroundColor: "#f8f9fa",
              color: "#343a40",
              padding: "10px",
              borderRadius: "5px",
              fontSize: "1rem",
            }}
          />
        </Card.Text>
        <div className="d-flex justify-content-between align-items-center mt-3 mb-1">
          <div className="d-flex align-items-center">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${createdBy.id || "default"}`}>
                  {createdBy.name}
                </Tooltip>
              }
            >
              <div className="symbol symbol-45px m-0">
                <img
                  src={createdBy.logo}
                  alt={createdBy.name}
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                />
              </div>
            </OverlayTrigger>
            {/* <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-listen">Listen to this report</Tooltip>
              }
            >
              <i
                className="fas fa-headphones-simple fs-2"
                style={{
                  cursor: "pointer",
                  marginLeft: "10px",
                  color: showAudioPlayer ? "#04c8c8" : "#99a1b7",
                }}
                onClick={toggleAudioPlayer}
              ></i>
            </OverlayTrigger> */}
          </div>
          <Button
            variant="link"
            className="p-0 text-primary fw-semibold fs-7"
            onClick={handleReadMore}
            style={{ marginLeft: "auto" }}
          >
            Read More
          </Button>
        </div>
        {/* {showAudioPlayer && (
          <div style={{ width: "100%" }}>
            <AudioPlayer
              src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
              audioClassName="w-100 mt-2"
            />
          </div>
        )} */}
      </Card.Body>

      {isModalOpen && (
        <ReportCardModal
          title={`${reportTitles[reportKey]} (${formattedDate})`}
          content={content}
          createdBy={createdBy}
          onClose={handleCloseModal}
        />
      )}

      <DeleteConfirmationModal
        show={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirm}
        title="Delete Report"
        body="Are you sure you want to delete this report? This action cannot be undone."
      />
    </Card>
  );
};

export default ReportCard;
