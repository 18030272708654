/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";

import { KTIcon } from "../../../helpers";
import stores from "../../stores";

type AccountHeaderProps = {
  selectedTab: string;
  onTabChange: (tab: string) => void;
};

const AccountHeader: React.FC<AccountHeaderProps> = observer(
  ({ selectedTab, onTabChange }) => {
    const currentUser = stores.userStore.currentUser;

    return (
      <div className="card mb-5 mb-xl-10">
        <div className="card-body pt-9 pb-0">
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            <div className="me-7 mb-4">
              <div className="symbol symbol-100px symbol-lg-100px symbol-fixed position-relative">
                <img src={currentUser.picture} />

                {/* <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div> */}
              </div>
            </div>

            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <a className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                      {currentUser.name}
                    </a>
                    <a>
                      <KTIcon iconName="verify" className="fs-1 text-primary" />
                    </a>

                    {/* <a
                    className="btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_upgrade_plan"
                  >
                    Upgrade to Pro
                  </a> */}
                  </div>

                  <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                    <a className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                      <KTIcon iconName="profile-circle" className="fs-4 me-1" />

                      {stores.userStore.isCurrentUserAdmin ? "Admin" : "Member"}
                    </a>

                    {/* <a className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                      <KTIcon iconName="geolocation" className="fs-4 me-1" />
                      SF, Bay Area
                    </a> */}

                    <a className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                      <KTIcon iconName="sms" className="fs-4 me-1" />
                      {currentUser.email}
                    </a>
                  </div>
                </div>

                <div className="d-flex my-4">
                  {/* <a
                    className="btn btn-sm btn-light me-2"
                    id="kt_user_follow_button"
                  >
                    <KTIcon iconName="check" className="fs-3 d-none" />

                    <span className="indicator-label">Follow</span>

                    <span className="indicator-progress">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </a> */}

                  {/* <a
                    className="btn btn-sm btn-primary me-3"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_offer_a_deal"
                  >
                    Hire Me
                  </a> */}

                  {/* <div className="me-0">
                    <button
                      className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      data-kt-menu-flip="top-end"
                    >
                      <i className="bi bi-three-dots fs-3"></i>
                    </button>

                    <Dropdown1 />
                  </div> */}
                </div>
              </div>

              {/* <div className="d-flex flex-wrap flex-stack">
                <div className="d-flex flex-column flex-grow-1 pe-8">
                  <div className="d-flex flex-wrap">
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <KTIcon
                          iconName="arrow-up"
                          className="fs-3 text-success me-2"
                        />
                        <div className="fs-2 fw-bolder">4500$</div>
                      </div>

                      <div className="fw-bold fs-6 text-gray-400">Earnings</div>
                    </div>

                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <KTIcon
                          iconName="arrow-down"
                          className="fs-3 text-danger me-2"
                        />
                        <div className="fs-2 fw-bolder">75</div>
                      </div>

                      <div className="fw-bold fs-6 text-gray-400">Projects</div>
                    </div>

                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <KTIcon
                          iconName="arrow-up"
                          className="fs-3 text-success me-2"
                        />
                        <div className="fs-2 fw-bolder">60%</div>
                      </div>

                      <div className="fw-bold fs-6 text-gray-400">
                        Success Rate
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          {/* <div className="d-flex overflow-auto h-55px">
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
              <li className="nav-item">
                <a
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (window.location.pathname ===
                      "/emplayee-dasboard/overview" && "active")
                  }
                  href="/crafted/emplayee-dasboard/overview"
                >
                  Overview
                </a>
              </li>

              <li className="nav-item">
                <a
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (window.location.pathname ===
                      "/emplayee-dasboard/settings" && "active")
                  }
                  href="/crafted/emplayee-dasboard/settings"
                >
                  Settings
                </a>
              </li>
            </ul>
          </div> */}

          <div className="d-flex overflow-auto h-55px">
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
              <li className="nav-item">
                <button
                  onClick={() => onTabChange("overview")}
                  className={`nav-link text-active-primary me-6 ${
                    selectedTab === "overview" ? "active" : ""
                  }`}
                >
                  Overview
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={() => onTabChange("settings")}
                  className={`nav-link text-active-primary me-6 ${
                    selectedTab === "settings" ? "active" : ""
                  }`}
                >
                  Settings
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
);

export { AccountHeader };
