import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MarkdownPreview from "@uiw/react-markdown-preview";
import stores from "../../stores";
import {
  createDocumentMessage,
  chatGeneral,
  chatAvatar,
} from "../../../helpers/api";

interface ReportCardModalProps {
  title: string;
  content: string;
  onClose: () => void;
  createdBy: { name: string; logo: string; id?: number };
}

const ReportCardModal: React.FC<ReportCardModalProps> = ({
  title,
  content,
  onClose,
  createdBy,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleChatClick = async () => {
    setIsLoading(true);
    const documentName =
      createdBy.id === undefined
        ? `Report The Chief: ${title}`
        : `Report ${createdBy.name}: ${title}`;

    try {
      if (!stores.companyStore.selectedUserCompany) {
        throw new Error("No company selected.");
      }

      const companyId = stores.companyStore.selectedUserCompany.id;

      const newDocument = await stores.userDocumentStore.createUserDocument(
        documentName
      );

      stores.userDocumentStore.selectedUserDocument = newDocument;
      stores.userDocumentStore.lastSelectedDocumentId = newDocument.id;

      const createdAt = Date.now();
      const message = content;
      const messageReply = "";
      let newMessage;

      if (createdBy.id === undefined) {
        stores.companyAvatarStore.selectedCompanyAvatar = undefined;

        newMessage = await createDocumentMessage(
          newDocument.id,
          undefined,
          undefined,
          createdAt,
          encodeURIComponent(message),
          messageReply
        );

        await chatGeneral(
          newDocument.id,
          newMessage.id,
          companyId,
          encodeURIComponent(message),
          1000,
          false
        );
      } else {
        newMessage = await createDocumentMessage(
          newDocument.id,
          undefined,
          createdBy.id,
          createdAt,
          encodeURIComponent(message),
          messageReply
        );

        await chatAvatar(
          newDocument.id,
          newMessage.id,
          companyId,
          createdBy.id,
          encodeURIComponent(message),
          false
        );
      }

      onClose();

      setTimeout(() => {
        navigate("/chat", {
          state: {
            selectedAvatarId: createdBy.id,
            isTheChief: createdBy.id === undefined,
          },
        });
        setIsLoading(false);
      }, 300);
    } catch (error) {
      console.error("Failed to create chat document:", error);
      setIsLoading(false);
    }
  };

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Full Report</h5>
        <MarkdownPreview
          source={content}
          style={{
            backgroundColor: "#f8f9fa",
            color: "#343a40",
            padding: "10px",
            borderRadius: "4px",
            fontSize: "14px",
            lineHeight: "1.5",
            overflowX: "auto",
          }}
        />
        <div className="d-flex align-items-center flex-end mt-4">
          <img
            src={createdBy.logo}
            alt={createdBy.name}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              objectFit: "cover",
              marginRight: "10px",
            }}
          />
          <span className="text-muted" style={{ fontSize: "0.85rem" }}>
            {createdBy.name}
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button
            className="mx-5"
            variant="primary"
            onClick={handleChatClick}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Loading...
              </>
            ) : (
              "Chat"
            )}
          </Button>
          <Button variant="secondary" onClick={onClose} disabled={isLoading}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportCardModal;
