import { Component } from "react";
import { observer } from "mobx-react";

import stores from "../../../stores";

interface Props {
  onClose: () => void;
  apiKey: string;
  companyId: string;
}

interface State {
  isCompanyIdCopied: boolean;
  isApiKeyCopied: boolean;
  isSubscriptionIdCopied: boolean;
}

@observer
export default class GenerateAPIKeyModal extends Component<Props, State> {
  private subscriptionId: string = "";

  constructor(props: Props) {
    super(props);
    this.state = {
      isCompanyIdCopied: false,
      isApiKeyCopied: false,
      isSubscriptionIdCopied: false,
    };

    if (stores.userStore.currentUser.token_based_subscription) {
      const subscriptionItem =
        stores.userStore.currentUser.token_based_subscription.items.data.find(
          (item) =>
            item.plan.id ===
            stores.companyModelStore.models.find(
              (model) => model.id.toString() === "8"
            )?.stripe_price_id
        );

      if (subscriptionItem) {
        this.subscriptionId = subscriptionItem.id;
      }
    }
  }

  handleCopyCompanyId = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        this.setState({ isCompanyIdCopied: true }, () => {
          setTimeout(() => {
            this.setState({ isCompanyIdCopied: false });
          }, 2000);
        });
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  handleCopyApiKey = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        this.setState({ isApiKeyCopied: true }, () => {
          setTimeout(() => {
            this.setState({ isApiKeyCopied: false });
          }, 2000);
        });
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  handleCopySubscriptionId = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        this.setState({ isSubscriptionIdCopied: true }, () => {
          setTimeout(() => {
            this.setState({ isSubscriptionIdCopied: false });
          }, 2000);
        });
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  render() {
    const { onClose, apiKey, companyId } = this.props;

    return (
      <>
        <div
          className="modal fade show d-block"
          id="kt_modal_add_company_users_apps"
          role="dialog"
          tabIndex={-1}
          aria-modal="true"
        >
          <div className="modal-dialog modal-dialog-centered mw-500px">
            <div className="modal-content">
              <div className="modal-body py-lg-10 px-lg-10">
                <div className="current" data-kt-stepper-element="content">
                  <>
                    <div className="fw-bolder text-dark fs-4 mb-1">
                      Warning: API Key Usage
                    </div>

                    <span className="text-gray-700 mt-1 fw-semibold fs-6">
                      Do not close the page without saving your API Key,
                      Subscription ID and Company ID. If you lose your API Key,
                      you will need to create a new one, which means the current
                      API Key will be invalidated.
                    </span>
                  </>

                  <>
                    <div className="fw-bolder text-primary fs-4 mb-1 mt-8">
                      Your Company ID
                      <button
                        onClick={() => this.handleCopyCompanyId(companyId)}
                        className="btn btn-icon w-auto me-2"
                        style={{
                          marginLeft: 10,
                          height: "auto",
                          border: "1px solid var(--bs-gray-300)",
                          background: this.state.isCompanyIdCopied
                            ? "#d4edda"
                            : "#f8f5ff", // Change color when copied
                          borderRadius: "15px",
                          padding: "5px 10px",
                          borderColor: this.state.isCompanyIdCopied
                            ? "var(--bs-success)"
                            : "var(--bs-gray-300)",
                        }}
                      >
                        <i
                          className={`fas fa-${
                            this.state.isCompanyIdCopied ? "check" : "copy"
                          } p-0`} // Change icon when copied
                          style={{
                            color: this.state.isCompanyIdCopied
                              ? "var(--bs-success)"
                              : "grey",
                            fontSize: "1.2rem",
                          }}
                        ></i>
                      </button>
                    </div>

                    <span className="fw-bolder text-gray-700 mt-1 fw-semibold fs-6">
                      {stores.companyStore.selectedUserCompany?.id}
                    </span>
                  </>

                  <>
                    <div className="fw-bolder text-primary fs-4 mb-1 mt-3">
                      Your Subscription ID
                      <button
                        onClick={() =>
                          this.handleCopySubscriptionId(this.subscriptionId)
                        } // Use the new handler
                        className="btn btn-icon w-auto me-2"
                        style={{
                          marginLeft: 10,
                          height: "auto",
                          border: "1px solid var(--bs-gray-300)",
                          background: this.state.isSubscriptionIdCopied
                            ? "#d4edda"
                            : "#f8f5ff",
                          borderRadius: "15px",
                          padding: "5px 10px",
                          borderColor: this.state.isSubscriptionIdCopied
                            ? "var(--bs-success)"
                            : "var(--bs-gray-300)",
                        }}
                      >
                        <i
                          className={`fas fa-${
                            this.state.isSubscriptionIdCopied ? "check" : "copy"
                          } p-0`}
                          style={{
                            color: this.state.isSubscriptionIdCopied
                              ? "var(--bs-success)"
                              : "grey",
                            fontSize: "1.2rem",
                          }}
                        ></i>
                      </button>
                    </div>

                    <span className="fw-bolder text-gray-700 mt-1 fw-semibold fs-6">
                      {this.subscriptionId}
                    </span>
                  </>

                  <>
                    <div className="fw-bolder text-primary fs-4 mb-1 mt-3">
                      New API Key
                      <button
                        onClick={() => this.handleCopyApiKey(apiKey)}
                        className="btn btn-icon w-auto me-2"
                        style={{
                          marginLeft: 10,
                          height: "auto",
                          border: "1px solid var(--bs-gray-300)",
                          background: this.state.isApiKeyCopied
                            ? "#d4edda"
                            : "#f8f5ff", // Change color when copied
                          borderRadius: "15px",
                          padding: "5px 10px",
                          borderColor: this.state.isApiKeyCopied
                            ? "var(--bs-success)"
                            : "var(--bs-gray-300)",
                        }}
                      >
                        <i
                          className={`fas fa-${
                            this.state.isApiKeyCopied ? "check" : "copy"
                          } p-0`} // Change icon when copied
                          style={{
                            color: this.state.isApiKeyCopied
                              ? "var(--bs-success)"
                              : "grey",
                            fontSize: "1.2rem",
                          }}
                        ></i>
                      </button>
                    </div>

                    <span className="fw-bolder text-gray-700 mt-1 fw-semibold fs-6">
                      {apiKey}
                    </span>
                  </>
                </div>

                <div className="d-flex flex-center pt-10">
                  <div>
                    <button
                      type="reset"
                      className="btn btn-outline btn-outline-gray me-4 fw-bolder px-6 py-3"
                      onClick={onClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-backdrop fade show"></div>
      </>
    );
  }
}
