import React from "react";

import { CompanyReport } from "../../models/CompanyReport";
import ReportCard from "./ReportCard";

interface ReportRowProps {
  report: CompanyReport;
}

type ReportKey = "report1" | "report2" | "report3";

const ReportRow: React.FC<ReportRowProps> = ({ report }) => {
  const reportKeys: ReportKey[] = ["report1", "report2", "report3"];

  return (
    <div className="row pb-3 border border-gray-200 p-3 rounded m-0 d-flex flex-wrap align-items-stretch">
      {reportKeys.map((key) => (
        <div key={key} className="col d-flex">
          <ReportCard report={report} reportKey={key} />
        </div>
      ))}
    </div>
  );
};

export default ReportRow;
