/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";
import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyReport } from "../../app/models/CompanyReport";

export default async function getCompanyTheChiefReports(
  company_id: number
): Promise<CompanyReport[]> {
  const client = ApolloClientHelper.getApolloClient();

  const query = gql`
    query CompanyTheChiefReportsQuery($company_id: ID!) {
      companyTheChiefReports(company_id: $company_id) {
        id
        company_id
        company_avatar_id
        report1
        report2
        report3
        date
      }
    }
  `;

  try {
    const { data } = await client.query({
      query,
      variables: { company_id },
      fetchPolicy: "no-cache",
    });
    return get(data, "companyTheChiefReports", []);
  } catch (error) {
    console.error("Failed to fetch company reports:", error);
    throw error;
  }
}
