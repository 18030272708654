/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";
import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyReport } from "../../app/models/CompanyReport";

export default async function getCompanyAvatarReports(
  company_id: number,
  company_avatar_id: number
): Promise<CompanyReport[]> {
  const client = ApolloClientHelper.getApolloClient();

  const query = gql`
    query CompanyAvatarReportsQuery($company_id: ID!, $company_avatar_id: ID!) {
      companyAvatarReports(
        company_id: $company_id
        company_avatar_id: $company_avatar_id
      ) {
        id
        company_id
        company_avatar_id
        report1
        report2
        report3
        date
      }
    }
  `;

  try {
    const { data } = await client.query({
      query,
      variables: { company_id, company_avatar_id },
      fetchPolicy: "no-cache",
    });
    return get(data, "companyAvatarReports", []);
  } catch (error) {
    console.error("Failed to fetch avatar reports:", error);
    throw error;
  }
}
