export const fetchAirtableData = async (): Promise<any[]> => {
  const response = await fetch(
    `${process.env.REACT_APP_AIRTABLE_INSTRUCTIONS_TABLE_URL}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_TOKEN_ID}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch data: ${response.statusText}`);
  }

  const data = await response.json();
  return data.records.map((record: any) => record.fields);
};
