/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";

type Props = {
  question: string;
  isAIReady: boolean;
  onPress: (question: string) => void;
};

const SuggestedQuestionItem: FC<Props> = ({ question, isAIReady, onPress }) => {
  return (
    <div
      style={{
        cursor: "pointer",
      }}
      onClick={() => (isAIReady ? onPress(question) : {})}
      className="card me-2 mt-2"
    >
      <div className="d-flex justify-content-center text-center flex-column p-3 rounded bg-state-light border border-gray-500">
        <a className="text-gray-800 d-flex flex-column">
          <div className="fs-7">
            <span>{question}</span>
          </div>
        </a>
      </div>
    </div>
  );
};

export { SuggestedQuestionItem };
