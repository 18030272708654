import React from "react";
import { observer } from "mobx-react";
import { PageTitle, PageLink } from "../../../layout/core";
import AiManagerPage from "./AiManagerPage";

const aiManagerBreadCrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/ai-manager",
    isSeparator: false,
    isActive: false,
  },
];

@observer
class AiManagerWrapper extends React.Component {
  render() {
    return (
      <>
        <PageTitle breadcrumbs={aiManagerBreadCrumbs}>AI Manager</PageTitle>
        <AiManagerPage />
      </>
    );
  }
}

export default AiManagerWrapper;
