/* @flow */

import { ChatResult } from "../../app/models/ChatResult";
import { CompanyModel } from "../../app/models/CompanyModel";
import stores from "../../app/stores";

export default async function chat(
  userDocumentId: number,
  documentMessageId: number,
  companyModel: CompanyModel,
  companyId: number,
  appId: number,
  prompt: string,
  isWebSearchActive: boolean
): Promise<ChatResult> {
  return new Promise(async (resolve, reject) => {
    let subscriptionId: string | undefined = undefined;

    if (stores.userStore.currentUser.subscription) {
      const subscriptionItem =
        stores.userStore.currentUser.subscription.items.data.find(
          (item) => item.plan.id === companyModel.model.stripe_price_id
        );

      if (subscriptionItem) {
        subscriptionId = subscriptionItem.id;
      }
    }

    fetch(`${process.env.REACT_APP_API_URL}/chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "client-id": stores.userStore.socketClientId,
      },
      body: JSON.stringify({
        subscriptionId: subscriptionId,
        userDocumentId: userDocumentId,
        documentMessageId: documentMessageId,
        companyModelId: companyModel.id,
        companyId: companyId,
        appId: appId,
        prompt: prompt,
        k: 1000,
        isWebSearchActive: isWebSearchActive,
      }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const result: ChatResult = data;

          resolve(result);
        } else {
          reject();
        }
      });
  });
}
