import { action, observable } from "mobx";
import { CompanyReport } from "../models/CompanyReport";
import getCompanyTheChiefReports from "../../helpers/api/getCompanyTheChiefReports";
import getCompanyAvatarReports from "../../helpers/api/getCompanyAvatarReports";
import deleteCompanyReport from "../../helpers/api/deleteCompanyReport";
import updateCompanyReport from "../../helpers/api/updateCompanyReport";

import RootStore from "./RootStore";

export default class CompanyReportsStore {
  @observable companyReports: CompanyReport[] = [];
  @observable isLoading: boolean = false;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  getCompanyReports = async () => {
    this.isLoading = true;
    try {
      const company_id = Number(
        this.rootStore.companyStore.selectedUserCompany?.id
      );

      if (!company_id) {
        console.error("No company ID selected.");
        return;
      }

      let reports: any[] = [];

      const avatars = this.rootStore.companyAvatarStore.companyAvatars.filter(
        (avatar) => avatar.company_id === company_id
      );

      for (const avatar of avatars) {
        const avatarReports = await getCompanyAvatarReports(
          company_id,
          avatar.id
        );
        reports = [...reports, ...avatarReports];
      }

      const theChiefReports = await getCompanyTheChiefReports(company_id);

      reports = [...reports, ...theChiefReports];

      reports.sort((a, b) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateB - dateA;
      });

      this.companyReports = reports;
    } catch (error) {
      console.error("Failed to fetch company reports:", error);
    } finally {
      this.isLoading = false;
    }
  };

  @action
  updateCompanyReport = async (
    updatedReport: Partial<CompanyReport>
  ): Promise<boolean> => {
    this.isLoading = true;
    try {
      if (!updatedReport.company_id) {
        updatedReport.company_id =
          this.rootStore.companyStore.selectedUserCompany?.id;
      }
      if (!updatedReport.date) {
        updatedReport.date = Date.now();
      }

      const isUpdated = await updateCompanyReport(updatedReport);
      if (isUpdated) {
        this.companyReports = this.companyReports.map((report) =>
          report.id === updatedReport.id
            ? { ...report, ...updatedReport }
            : report
        );
        await this.getCompanyReports();
      }
      return isUpdated;
    } catch (error) {
      console.error("Failed to update company report:", error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  @action
  deleteCompanyReport = async (id: number): Promise<boolean> => {
    this.isLoading = true;
    try {
      const isDeleted = await deleteCompanyReport(id);
      if (isDeleted) {
        this.companyReports = this.companyReports.filter((r) => r.id !== id);
        await this.getCompanyReports();
      }
      return isDeleted;
    } catch (error) {
      console.error("Failed to delete company report:", error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  };
}
