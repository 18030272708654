import React, { useState, useRef } from "react";

interface AudioPlayerProps {
  src: string;
  audioClassName?: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src, audioClassName }) => {
  const [audioLoading, setAudioLoading] = useState(true);
  const audioRef = useRef<HTMLAudioElement>(null);

  const handleAudioLoad = () => {
    setAudioLoading(false);
  };

  return (
    <div className="audio-player">
      {audioLoading && <div>Loading audio...</div>}
      <audio
        ref={audioRef}
        src={src}
        onCanPlay={handleAudioLoad}
        onCanPlayThrough={handleAudioLoad}
        onError={() => setAudioLoading(false)}
        controls
        className={audioClassName}
      />
    </div>
  );
};

export default AudioPlayer;
