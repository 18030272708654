/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { MentionsInput, Mention, SuggestionDataItem } from "react-mentions";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { NewSessionData, StreamingAvatarApi } from "@heygen/streaming-avatar";
import ReactGA from "react-ga4";

import {
  chat,
  chatAvatar,
  chatGeneral,
  createDocumentMessage,
  deleteDocumentMessage,
  getAvatar,
  getCompanyUserDepartments,
  getDocumentMessages,
  stopChat,
  updateDocumentMessage,
} from "../../../helpers/api";
import Functions from "../../../helpers/Functions";
import {
  ConversationMode,
  CompanyAppStatus,
  FeatureType,
} from "../../../helpers/Enums";
import SocketHelper from "../../../helpers/SocketHelper";
import ExportHelper from "../../../helpers/ExportHelper";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { UserDocument } from "../../models/UserDocument";
import { DocumentMessage } from "../../models/DocumentMessage";
import { CompanyModel } from "../../models/CompanyModel";
import { CompanyApp } from "../../models/CompanyApp";
import { CompanyAvatar } from "../../models/CompanyAvatar";
import i18n from "../../../i18n";
import { ChatResult } from "../../models/ChatResult";
import stores from "../../stores";

import MessageItem from "./MessageItem";

interface MentionItem {
  id: string;
  display: string;
}

interface State {
  chatUpdateFlag: boolean;
  message: string;
  messages: DocumentMessage[];
  isLoading: boolean;
  isAIReady: boolean;
  userDocument: UserDocument;
  selectedCompanyAppIds: string[];
  selectedCompanyModel?: CompanyModel;
  selectedCompanyAvatar?: CompanyAvatar;
  recording: boolean;
  avatar: StreamingAvatarApi | undefined;
  avatarSessionData: NewSessionData | undefined;
  avatarStream: MediaStream | undefined;
  conversationMode: ConversationMode;
  filteredCompanyApps: CompanyApp[];
  canUseTheChief: boolean;
  canUseLiveAvatar: boolean;
  isElectron: boolean;
  refreshTime: number;
  appId?: number;
  avatarId?: number;
  mentions: MentionItem[];
  startNewChat: boolean;
  isWebSearchActive: boolean;
  isThinkDeeplyLoading: boolean;
}

interface Props {
  mentionInputId?: string;
  voiceMessageButtonId?: string;
  downloadButtonId?: string;
  webSearchButtonId?: string;
  userDocument: UserDocument;
  selectedCompanyModel?: CompanyModel;
  selectedCompanyAvatar?: CompanyAvatar;
  canUseLiveAvatar: boolean;
  canUseTheChief: boolean;
}

@observer
export default class ChatInner extends React.Component<Props, State> {
  private messagesEndRef: any;
  private avatarTimeoutId: any;
  private refreshTimeoutId: any;
  private checkTokenUsageTimeoutId: any;
  private mediaStream: HTMLVideoElement | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      chatUpdateFlag: false,
      message: "",
      messages: [],
      isLoading: true,
      isAIReady: true,
      userDocument: this.props.userDocument,
      selectedCompanyAppIds: [],
      selectedCompanyAvatar: this.props.selectedCompanyAvatar,
      selectedCompanyModel: this.props.selectedCompanyModel,
      recording: false,
      avatar: undefined,
      avatarSessionData: undefined,
      avatarStream: undefined,
      conversationMode: ConversationMode.Chat,
      filteredCompanyApps: [],
      canUseTheChief: this.props.canUseTheChief,
      canUseLiveAvatar: this.props.canUseLiveAvatar,
      isElectron: false,
      refreshTime: 0,
      mentions: [],
      startNewChat: false,
      isWebSearchActive: false,
      isThinkDeeplyLoading: false,
    };
  }

  componentWillMount(): void {
    const isWebSearchActive =
      localStorage.getItem("isWebSearchActive") === "true";
    this.setState({ isWebSearchActive });

    this.setState({ isElectron: window.electronAPIs?.isElectron ?? false });

    SocketHelper.addMessageHandler(this.handleMessage);

    this.getFilteredCompanyApps();

    this.getMessages();

    const canUseLiveAvatar = this.props.canUseLiveAvatar;
    setTimeout(async () => {
      if (canUseLiveAvatar) await this.getAvatar();
      stores.companyAvatarStore.isAvatarLoading = false;
      this.setState({ isAIReady: true });
    }, 100);

    if (this.refreshTimeoutId) clearInterval(this.refreshTimeoutId);

    this.refreshTimeoutId = setInterval(async () => {
      this.setState({ refreshTime: Date.now() });
    }, 1000);
  }

  componentWillUnmount(): void {
    if (this.refreshTimeoutId) clearInterval(this.refreshTimeoutId);
  }

  componentWillReceiveProps(nextProps: any) {
    const {
      selectedCompanyAvatar,
      avatar,
      avatarSessionData,
      canUseLiveAvatar,
    } = this.state;

    const isUserDocumentChanged =
      this.state.userDocument.id !== nextProps.userDocument.id;

    this.setState(
      {
        userDocument: nextProps.userDocument,
        canUseTheChief: nextProps.canUseTheChief,
        canUseLiveAvatar: nextProps.canUseLiveAvatar,
      },
      () => {
        if (isUserDocumentChanged) this.getMessages();

        if (nextProps.selectedCompanyAvatar !== selectedCompanyAvatar) {
          setTimeout(async () => {
            if (avatar && avatarSessionData) {
              await this.stopAvatar();
              await this.getAvatar();
            }
          }, 100);
        } else if (nextProps.canUseLiveAvatar) {
          setTimeout(async () => {
            if (nextProps.canUseLiveAvatar !== canUseLiveAvatar) {
              await this.stopAvatar();
              await this.getAvatar();
            }
          }, 100);
        }

        this.setState({
          selectedCompanyModel: nextProps.selectedCompanyModel,
          selectedCompanyAvatar: nextProps.selectedCompanyAvatar,
        });
      }
    );
  }

  private refreshMessagesTime = () => {};

  private handleMessage = (message: any) => {
    const {
      messages,
      userDocument,
      avatar,
      avatarSessionData,
      conversationMode,
    } = this.state;

    if (message.processId === 1) {
      if (this.checkTokenUsageTimeoutId)
        clearTimeout(this.checkTokenUsageTimeoutId);

      this.checkTokenUsageTimeoutId = setTimeout(async () => {
        stores.companyStore.checkCompanyFreeToken();
      }, 3000);

      if (userDocument.id === message.userDocumentId) {
        if (messages.length > 0) {
          const lastMessage = messages[messages.length - 1];
          if (lastMessage) {
            lastMessage.message_reply = Functions.decodeMessage(
              message.streamedResult
            );

            this.toggleChatUpdateFlag();

            setTimeout(() => {
              if (this.messagesEndRef)
                this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
            }, 200);

            if (conversationMode === ConversationMode.Avatar) {
              if (this.avatarTimeoutId) clearTimeout(this.avatarTimeoutId);

              this.avatarTimeoutId = setTimeout(async () => {
                if (!avatar || !avatarSessionData) return;

                const decodedMessage = Functions.decodeMessage(
                  lastMessage.message_reply
                );

                stores.companyAvatarStore.isAvatarLoading = false;

                await this.textToSpeechAvatar(decodedMessage);
              }, 3000);
            } else {
              stores.companyAvatarStore.isAvatarLoading = false;
            }
          }
        }
      }
    } else if (message.processId === 7) {
      if (userDocument.id === message.userDocumentId) {
        if (messages.length > 0) {
          const lastMessage = messages[messages.length - 1];
          if (lastMessage) {
            lastMessage.suggested_questions = Functions.completeArrayFromString(
              Functions.decodeMessage(message.streamedResult)
            );

            setTimeout(() => {
              if (this.messagesEndRef)
                this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
            }, 200);
          }
        }
      }
    }
  };

  private handleSpeechToAvatar = async (message: string) => {
    await this.handleConversationModeChange(ConversationMode.Avatar);

    this.textToSpeechAvatar(message);
  };

  private textToSpeechAvatar(text: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const { avatar, avatarSessionData } = this.state;
      if (!avatar || !avatarSessionData) return;

      this.setState({ conversationMode: ConversationMode.Avatar }, async () => {
        const parts: string[] = [];
        for (let i = 0; i < text.length; i += 500) {
          parts.push(text.substring(i, i + 500));
        }

        for (const part of parts) {
          if (this.state.conversationMode === ConversationMode.Avatar) {
            await avatar.speak({
              taskRequest: {
                text: part,
                sessionId: avatarSessionData.sessionId,
              },
            });
          } else {
            resolve(true);
            return;
          }
        }
      });
    });
  }

  private handleConversationModeChange = async (mode: ConversationMode) => {
    const canUseAvatar = await stores.userStore.checkSubscribedFeatureType(
      FeatureType.LiveAvatar
    );

    ReactGA.event({
      category: "chat_page",
      action: "change_conversation_mode",
      label: "change_conversation_mode_button",
    });

    if (mode === ConversationMode.Avatar && !canUseAvatar) {
      toast.error(i18n.ToastMessages.liveAvatarError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    this.setState({ conversationMode: mode });
  };

  private getFilteredCompanyApps = async () => {
    const companyUser = stores.companyUserStore.companyUsers.find(
      (user) =>
        user.user_id.toString() === stores.userStore.currentUser.id.toString()
    );

    if (companyUser) {
      const companyuserDepartment = await getCompanyUserDepartments(
        companyUser?.id
      );
      const departmentIds = companyuserDepartment.map(
        (item) => item.department_id
      );

      const matchingAppIds = new Set(
        stores.companyAppStore.appDepartments
          .filter((appDept: { department_id: number }) =>
            departmentIds.includes(appDept.department_id)
          )
          .map((appDept: { app_id: any }) => appDept.app_id)
      );

      const filteredCompanyApps = stores.companyAppStore.companyApps.filter(
        (app: CompanyApp) => matchingAppIds.has(app.app_id)
      );

      this.setState({ filteredCompanyApps });
    }
  };

  private getMessages = async () => {
    const { userDocument } = this.state;
    this.setState({ isLoading: true });
    const messages = await getDocumentMessages(userDocument.id);

    this.setState({ messages, isLoading: false });

    setTimeout(() => {
      if (this.messagesEndRef)
        this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  private stopAvatar = async () => {
    const { avatar, avatarSessionData } = this.state;

    if (!avatar || !avatarSessionData) return;
    console.log("stop avatar");

    await avatar.stopAvatar({
      stopSessionRequest: { sessionId: avatarSessionData.sessionId },
    });
  };

  private getAvatar(tryCount: number = 1): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const { selectedCompanyAvatar } = this.state;

      this.setState({ isAIReady: false });
      stores.companyAvatarStore.isAvatarLoading = true;

      const avatar = await getAvatar();

      if (!avatar) return;

      try {
        avatar
          .createStartAvatar(
            {
              newSessionRequest: {
                quality: "high",
                avatarName: selectedCompanyAvatar
                  ? "305b0cbacae44bafa7105c7805701ec3"
                  : "josh_lite3_20230714",
                voice: {
                  voiceId: selectedCompanyAvatar
                    ? "0994ba6ed68847f5816259a96a96141a"
                    : "5074d5f025b34d059e2e928ef4f5dc95",
                },
              },
            },
            (string: any) => console.log(string)
          )
          .then((newSessionData) => {
            if (this.mediaStream) {
              this.mediaStream.srcObject = avatar.mediaStream;

              this.mediaStream.onloadedmetadata = () => {
                this.mediaStream!.play();

                this.setState(
                  {
                    avatar,
                    avatarSessionData: newSessionData,
                    avatarStream: avatar.mediaStream,
                  },
                  () => {
                    resolve(true);
                  }
                );
              };
            }
          })
          .catch((error) => {
            if (tryCount < 5) {
              setTimeout(() => {
                console.log("Avatar session try count: ", tryCount);
                this.getAvatar(++tryCount);
              }, 5000);
            } else {
              console.error("Avatar session data error: ", error);
              this.setState({ isAIReady: true });
              reject();
            }
          });
      } catch (error) {
        if (tryCount < 5) {
          setTimeout(() => {
            console.log("Avatar session try count: ", tryCount);
            this.getAvatar(++tryCount);
          }, 5000);
        } else {
          console.error("Avatar session data catch: ", error);
          this.setState({ isAIReady: true });
          reject();
        }
      }
    });
  }

  public clear = async () => {
    const { messages } = this.state;

    this.setState({ isLoading: true });

    for (let index = 0; index < messages.length; index++) {
      const message = messages[index];

      await deleteDocumentMessage(message.id);
    }

    this.setState({ isLoading: false, messages: [] });

    this.toggleChatUpdateFlag();
  };

  private recognition = new (window.SpeechRecognition ||
    window.webkitSpeechRecognition)();

  private handleOnRecord = async () => {
    ReactGA.event({
      category: "chat_page",
      action: "on_record_button",
      label: "on_record_button",
    });

    this.recognition.continuous = true;
    this.recognition.interimResults = true;

    if (!this.state.recording) {
      this.recognition.start();
      this.setState({ recording: true });
    } else {
      this.recognition.stop();
      this.setState({ recording: false });
    }

    let finalTranscript = "";

    this.recognition.onresult = (event) => {
      let interimTranscript = "";

      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript;
        } else {
          interimTranscript += event.results[i][0].transcript;
        }
      }

      this.setState({ message: finalTranscript + interimTranscript });
    };

    this.recognition.onerror = (event) => {
      console.error("Speech recognition error", event.error);
      this.setState({ recording: false });
    };

    this.recognition.onend = () => {
      this.setState((prevState) => ({
        message: prevState.message,
      }));
      this.setState({ recording: false });
    };
  };

  private getLastMessage = async () => {
    const { userDocument } = this.state;

    const messages = await getDocumentMessages(userDocument.id);

    if (messages.length === 0) {
      return null;
    }

    const lastMessage = messages[messages.length - 1];

    if (
      lastMessage.message_reply === null ||
      lastMessage.message_reply.trim() === ""
    ) {
      return lastMessage;
    }

    return null;
  };

  private handleThinkDeeperClick = async (
    documentMessage: DocumentMessage,
    k: number
  ) => {
    const { isAIReady } = this.state;
    if (!isAIReady) return;

    this.setState({ isAIReady: false, isThinkDeeplyLoading: true });
    stores.companyAvatarStore.isAvatarLoading = true;

    const emoji = "🧠";

    const decodedMessage = Functions.decodeMessage(documentMessage.message);

    const modifiedMessage = decodedMessage.startsWith(emoji)
      ? decodedMessage
      : `${emoji} ${decodedMessage}`;
    const encodedMessage = encodeURIComponent(
      Functions.sanitizeAndStoreMentions(modifiedMessage)
    );

    const newUserMessage = await createDocumentMessage(
      this.state.userDocument.id,
      undefined,
      undefined,
      Date.now(),
      encodedMessage,
      ""
    );

    this.setState(
      (prevState) => ({
        messages: [...prevState.messages, newUserMessage],
      }),
      () => {
        if (this.messagesEndRef)
          this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
      }
    );

    if (stores.companyStore.selectedUserCompany) {
      const chatResult = await chatGeneral(
        this.state.userDocument.id,
        newUserMessage.id,
        stores.companyStore.selectedUserCompany.id,
        encodedMessage,
        k,
        this.state.isWebSearchActive
      );

      if (chatResult) {
        const messageIndex = this.state.messages.findIndex(
          (m) => m.id === newUserMessage.id
        );
        if (messageIndex !== -1) {
          const modifiedMessageReply = `${emoji} ${chatResult.message}`;

          const updatedMessage: DocumentMessage = {
            ...this.state.messages[messageIndex],
            message_reply: encodeURIComponent(modifiedMessageReply),
            source_documents: chatResult.sourceDocs
              ? encodeURIComponent(chatResult.sourceDocs.join(","))
              : this.state.messages[messageIndex].source_documents,
          };

          const updatedMessages = [...this.state.messages];
          updatedMessages[messageIndex] = updatedMessage;

          await updateDocumentMessage(updatedMessage);

          this.setState({ messages: updatedMessages });
        }
      }
    }

    this.setState({ isAIReady: true, isThinkDeeplyLoading: false });
    stores.companyAvatarStore.isAvatarLoading = false;
  };

  private handleCopyToInput = (documentMessage: DocumentMessage) => {
    const decodedMessage = Functions.decodeMessage(documentMessage.message);
    this.setState({ message: decodedMessage });
  };

  private sendMessage = async () => {
    const {
      message,
      selectedCompanyAppIds,
      selectedCompanyModel,
      selectedCompanyAvatar,
      startNewChat,
    } = this.state;

    this.setState({ isAIReady: false });
    stores.companyAvatarStore.isAvatarLoading = true;

    if (startNewChat) {
      await stopChat(this.state.userDocument.id);

      let messageTextForName = Functions.sanitizeAndStoreMentions(message);

      if (messageTextForName.length > 50) {
        messageTextForName = messageTextForName.substring(0, 50) + "...";
      }

      const newUserDocument = await stores.userDocumentStore.createUserDocument(
        "New Chat"
      );

      await new Promise<void>((resolve) => {
        this.setState(
          {
            userDocument: newUserDocument,
            startNewChat: false,
            messages: [],
          },
          resolve
        );
      });

      stores.userDocumentStore.selectedUserDocument = newUserDocument;
      stores.userDocumentStore.lastSelectedDocumentId = newUserDocument.id;
    }

    ReactGA.event({
      category: "chat_page",
      action: "send_chat_message",
      label: "send_chat_message_button",
    });

    setTimeout(async () => {
      const lastMessage = await this.getLastMessage();
      if (lastMessage) {
        deleteDocumentMessage(lastMessage.id);

        this.setState((prevState) => ({
          messages: prevState.messages.filter(
            (msg) => msg.id !== lastMessage.id
          ),
        }));
      }
    }, 200);

    if (selectedCompanyModel && selectedCompanyAppIds.length === 0) {
      toast.warn(i18n.ToastMessages.selectedAppIssue, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      this.setState({ isAIReady: true });
      stores.companyAvatarStore.isAvatarLoading = false;

      return;
    }

    const encodedMessage = encodeURIComponent(
      Functions.sanitizeAndStoreMentions(message)
    );

    if (!encodedMessage) return;

    if (
      (!stores.companyStore.selectedUserCompany ||
        !stores.companyStore.selectedUserCompany.is_index_created) &&
      !selectedCompanyModel &&
      !selectedCompanyAvatar
    ) {
      return;
    }

    this.setState({ isAIReady: false });
    stores.companyAvatarStore.isAvatarLoading = true;

    if (this.state.messages.length === 0) {
      const sanitizedMessage = Functions.sanitizeAndStoreMentions(message);

      const trimmedFileName = Functions.getTrimmedFileName(sanitizedMessage);

      const updatedUserDocument = { ...this.state.userDocument };
      updatedUserDocument.name = encodeURIComponent(trimmedFileName);
      await stores.userDocumentStore.updateUserDocument(updatedUserDocument);
      this.setState({ userDocument: updatedUserDocument });
    }

    const createNewUserMessage = async (
      appId: number | undefined,
      avatarId: number | undefined
    ) => {
      const newUserMessage = await createDocumentMessage(
        this.state.userDocument.id,
        appId,
        avatarId,
        Date.now(),
        encodedMessage,
        ""
      );

      this.setState(
        (prevState) => ({
          messages: [...prevState.messages, newUserMessage],
          message: "",
          appId: undefined,
          avatarId: undefined,
        }),
        () => {
          if (this.messagesEndRef) {
            this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
          }
        }
      );

      return newUserMessage;
    };

    const handleChatResult = async (
      chatResult: ChatResult,
      newUserMessage: DocumentMessage
    ) => {
      if (chatResult) {
        const messageIndex = this.state.messages.findIndex(
          (m) => m.id === newUserMessage.id
        );
        if (messageIndex !== -1) {
          this.state.messages[messageIndex].message_reply = encodeURIComponent(
            chatResult.message
          );
          if (chatResult.sourceDocs) {
            this.state.messages[messageIndex].source_documents =
              encodeURIComponent(chatResult.sourceDocs.join(","));
          }
          await updateDocumentMessage(this.state.messages[messageIndex]);
          this.setState({ messages: this.state.messages });
          scrollToBottom();
        }
      }
    };

    const scrollToBottom = () => {
      if (this.messagesEndRef) {
        this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
      }
    };

    const thechiefFeature = await stores.userStore.checkSubscribedFeatureType(
      FeatureType.TheChief
    );

    if (
      thechiefFeature &&
      stores.companyStore.selectedUserCompany &&
      stores.companyStore.selectedUserCompany.is_index_created &&
      !selectedCompanyAvatar &&
      !selectedCompanyModel
    ) {
      const newUserMessage = await createNewUserMessage(undefined, undefined);

      const chatResult = await chatGeneral(
        this.state.userDocument.id,
        newUserMessage.id,
        stores.companyStore.selectedUserCompany.id,
        encodedMessage,
        1000,
        this.state.isWebSearchActive
      );

      await handleChatResult(chatResult, newUserMessage);
    } else if (selectedCompanyModel && !selectedCompanyAvatar) {
      for (let appId of selectedCompanyAppIds) {
        const companyApp = stores.companyAppStore.companyApps.find(
          (item) => item.id.toString() === appId
        );

        if (companyApp) {
          const newUserMessage = await createNewUserMessage(
            companyApp.id,
            undefined
          );
          const chatResult = await chat(
            this.state.userDocument.id,
            newUserMessage.id,
            selectedCompanyModel,
            companyApp.company_id,
            companyApp.app_id,
            encodedMessage,
            this.state.isWebSearchActive
          );
          await handleChatResult(chatResult, newUserMessage);
        }
      }
    } else if (!selectedCompanyModel && selectedCompanyAvatar) {
      const newUserMessage = await createNewUserMessage(
        undefined,
        selectedCompanyAvatar.id
      );
      const chatResult = await chatAvatar(
        this.state.userDocument.id,
        newUserMessage.id,
        selectedCompanyAvatar.company_id,
        selectedCompanyAvatar.id,
        encodedMessage,
        this.state.isWebSearchActive
      );
      await handleChatResult(chatResult, newUserMessage);
    }
    this.setState({ isAIReady: true });
  };

  private toggleChatUpdateFlag = () => {
    this.setState((prevState) => ({
      chatUpdateFlag: !prevState.chatUpdateFlag,
    }));
  };

  private onEnterPress = (e: any) => {
    const { isAIReady, canUseTheChief } = this.state;
    const isCurrentUserAdmin = stores.userStore.isCurrentUserAdmin;
    const hasSelectedAvatar = !!this.state.selectedCompanyAvatar;
    const isFreeUser = stores.userStore.isFreeUser;
    const hasSelectedModel = !!this.state.selectedCompanyModel;
    const hasMentionedApp = this.state.selectedCompanyAppIds.length > 0;

    if (
      e.keyCode === 13 &&
      e.shiftKey === false &&
      isAIReady &&
      (isCurrentUserAdmin || hasSelectedAvatar || canUseTheChief) &&
      (!isFreeUser || (hasSelectedModel && hasMentionedApp))
    ) {
      e.preventDefault();
      this.sendMessage();
    }
  };

  private handleShareClick = () => {
    const { messages, userDocument } = this.state;

    ReactGA.event({
      category: "chat_page",
      action: "share_click_button",
      label: "share_click_button",
    });

    ExportHelper.exportDocxFile(messages, userDocument);
  };

  private handleMessageChange = (e: any) => {
    this.setState({ message: e.target.value });

    const value = e.target.value;
    const idPattern = /@\[(?:@([A-Za-z0-9-_]+)|(.*?))\]\((\d+)\)/g;
    let match;
    const matches: string[] = [];

    while ((match = idPattern.exec(value)) !== null) {
      const id = match[3];
      matches.push(id);
    }

    this.setState({ selectedCompanyAppIds: matches });
  };

  private handleReplyLike = async (messageId: number, liked: boolean) => {
    const updatedMessages = this.state.messages.map((message) => {
      if (message.id === messageId) {
        return { ...message, is_liked: true };
      }
      return message;
    });

    this.setState({ messages: updatedMessages });

    const messageToUpdate = this.state.messages.find((m) => m.id === messageId);
    if (messageToUpdate) {
      await updateDocumentMessage({ ...messageToUpdate, is_liked: true });
    }
  };

  private handleReplyDisLike = async (messageId: number, liked: boolean) => {
    const updatedMessages = this.state.messages.map((message) => {
      if (message.id === messageId) {
        return { ...message, is_liked: false };
      }
      return message;
    });

    this.setState({ messages: updatedMessages });

    const messageToUpdate = this.state.messages.find((m) => m.id === messageId);
    if (messageToUpdate) {
      await updateDocumentMessage({ ...messageToUpdate, is_liked: false });
    }
  };

  private handleStopChat = async () => {
    const { userDocument } = this.state;
    ReactGA.event({
      category: "chat_page",
      action: "stop_chat_message",
      label: "stop_chat_message_button",
    });

    await stopChat(userDocument.id);
    try {
      const result = await stopChat(userDocument.id);

      if (result && result.ok) {
        this.setState({ isAIReady: true });
      }
    } catch (error) {
      console.error("Error stopping chat:", error);
    }
  };

  private renderSuggestionItem = (
    suggestion: SuggestionDataItem,
    search: string,
    highlightedDisplay: React.ReactNode,
    index: number,
    focused: boolean
  ) => {
    const companyApp = stores.companyAppStore.companyApps.find(
      (companyApp) => companyApp.id === suggestion.id
    );

    if (companyApp) {
      return (
        <div
          className={`d-flex align-items-center p-2 ${
            focused ? "bg-light-primary" : "bg-body"
          }`}
        >
          <div className="me-3 position-relative">
            <img
              src={toAbsoluteUrl(companyApp.app.logo)}
              className="align-self-center"
              style={{ width: 20 }}
              alt=""
            />
          </div>

          <div className="d-flex flex-column justify-content-center">
            <a className="fs-6 text-gray-800 fw-bolder text-hover-primary">
              {suggestion.display}
            </a>
          </div>
        </div>
      );
    }
  };

  render() {
    const {
      messages,
      message,
      isLoading,
      isAIReady,
      chatUpdateFlag,
      selectedCompanyModel,
      selectedCompanyAvatar,
      conversationMode,
      filteredCompanyApps,
      canUseTheChief,
      isElectron,
      refreshTime,
    } = this.state;
    const {
      canUseLiveAvatar,
      mentionInputId,
      voiceMessageButtonId,
      downloadButtonId,
      webSearchButtonId,
    } = this.props;

    const maxInputLength = selectedCompanyModel
      ? selectedCompanyModel.model.token_count / 3
      : 100000;

    const messagePlaceholderText = !stores.companyStorageStore
      .selectedCompanyStorage
      ? "Please connect your storage.."
      : stores.companyAppStore.companyApps.length === 0
      ? "Please connect at least one app.."
      : stores.companyAvatarStore.isAvatarLoading
      ? "Avatar is loading, please wait a moment.."
      : stores.userStore.isFreeUser
      ? "Choose a model and ask a question example: @slack [your question].."
      : stores.companyStore.selectedUserCompany &&
        stores.companyStore.selectedUserCompany.is_index_created &&
        !selectedCompanyModel &&
        !selectedCompanyAvatar
      ? canUseTheChief
        ? "Ask The Chief.."
        : "Choose a model or avatar and ask a question example: @slack [your question].."
      : !selectedCompanyModel && selectedCompanyAvatar
      ? `Ask the ${selectedCompanyAvatar.name}..`
      : selectedCompanyModel
      ? "Ask: @slack [your question].."
      : "Choose a model or avatar and ask a question example: @slack [your question]..";

    return (
      <div
        className="card-body d-flex flex-column"
        id={"kt_chat_messenger_body"}
        style={{
          height: "calc(100vh - 185px)",
          padding: "1rem",
        }}
      >
        <div
          className="d-flex bg-body align-items-center"
          style={{ width: 70 }}
        >
          <div
            className={`${
              conversationMode === ConversationMode.Chat
                ? "bg-light-primary"
                : "bg-light"
            } p-4`}
            onClick={() =>
              this.handleConversationModeChange(ConversationMode.Chat)
            }
            style={{
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
              cursor: "pointer",
            }}
          >
            <KTSVG
              path="media/icons/duotune/communication/com007.svg"
              className="svg-icon-1 me-2"
            />
          </div>

          <div
            className={`${
              conversationMode === ConversationMode.Avatar
                ? "bg-light-primary"
                : "bg-light"
            } p-4`}
            onClick={() =>
              this.handleConversationModeChange(ConversationMode.Avatar)
            }
            style={{
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
              cursor: "pointer",
            }}
          >
            <KTSVG
              path="media/icons/duotune/technology/teh002.svg"
              className="svg-icon-1 me-2"
            />
          </div>
        </div>

        <div
          style={{
            width: "100%",
            flex: 1,
            marginTop: 10,
            borderRadius: 10,
            textAlign: "center",
            display:
              conversationMode === ConversationMode.Avatar ? "block" : "none",
          }}
        >
          {canUseLiveAvatar ? (
            <div>
              <video
                playsInline
                autoPlay
                style={{ height: "80%", maxWidth: "80%", borderRadius: 10 }}
                ref={(ref) => (this.mediaStream = ref)}
                muted={conversationMode !== ConversationMode.Avatar}
                onPlay={() =>
                  (stores.companyAvatarStore.isAvatarLoading = false)
                }
                onLoad={() =>
                  (stores.companyAvatarStore.isAvatarLoading = false)
                }
              ></video>

              {stores.companyAvatarStore.isAvatarLoading ? (
                <span
                  className="spinner-border text-secondary ms-1"
                  style={{ position: "absolute", left: "50%", top: "44%" }}
                  role="status"
                ></span>
              ) : null}
            </div>
          ) : null}
        </div>

        {conversationMode === ConversationMode.Chat ? (
          <div
            className={clsx("scroll-y me-n5 pe-5")}
            style={{
              flex: 1,
              overflowY: "auto",
              marginTop: 15,
            }}
            id="messages"
            data-kt-element="messages"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-dependencies={
              "#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
            }
            data-kt-scroll-wrappers={
              "#kt_content, #kt_app_content, #kt_chat_messenger_body"
            }
          >
            {messages.length > 0 && !isLoading ? (
              messages.map((message, index) => (
                <MessageItem
                  key={message.id}
                  isAIReady={isAIReady}
                  chatUpdateFlag={chatUpdateFlag}
                  documentMessage={message}
                  index={index}
                  canUseLiveAvatar={canUseLiveAvatar}
                  onReplyLike={() => this.handleReplyLike(message.id, true)}
                  onReplyDislike={() =>
                    this.handleReplyDisLike(message.id, false)
                  }
                  onRewriteMessage={(documentMessage) => {
                    const decodedMessage = Functions.decodeMessage(
                      documentMessage.message
                    );

                    const app = stores.companyAppStore.companyApps.find(
                      (appItem) =>
                        appItem.app_id === documentMessage.company_app_id
                    );

                    const appMention = app
                      ? {
                          id: app.id.toString(),
                          display: app.app.name.replace(/\s+/g, ""),
                        }
                      : null;

                    const avatar =
                      stores.companyAvatarStore.companyAvatars.find(
                        (avatarItem) =>
                          avatarItem.id === documentMessage.company_avatar_id
                      );

                    const avatarMention = avatar
                      ? {
                          id: avatar.id.toString(),
                          display: avatar.name.replace(/\s+/g, ""),
                        }
                      : null;

                    const mentions: MentionItem[] = [];
                    if (appMention) mentions.push(appMention);
                    if (avatarMention) mentions.push(avatarMention);

                    const initialMessage = `${mentions
                      .map((m) => `@[${m.display}](${m.id})`)
                      .join(" ")} ${decodedMessage}`.trim();

                    this.setState({
                      message: initialMessage,
                      selectedCompanyAppIds: mentions.map((m) => m.id),
                      mentions,
                      appId: documentMessage.company_app_id || undefined,
                      avatarId: documentMessage.company_avatar_id || undefined,
                      startNewChat: true,
                    });
                  }}
                  onThinkDeeperClick={this.handleThinkDeeperClick}
                  onQuestionItemPress={(questionItem) =>
                    this.setState({ message: questionItem }, () => {
                      this.sendMessage();
                    })
                  }
                  onSpeechToAvatar={this.handleSpeechToAvatar}
                  refreshTime={refreshTime}
                  isThinkDeeplyLoading={this.state.isThinkDeeplyLoading}
                  onCopyToInput={this.handleCopyToInput}
                />
              ))
            ) : (
              <div className="text-gray-600 d-flex text-center w-100 align-content-center justify-content-center mt-6 mb-14">
                {isLoading ? (
                  <span
                    className="spinner-border text-secondary ms-1 position-relative"
                    role="status"
                  ></span>
                ) : (
                  "No message records found."
                )}
              </div>
            )}

            <div ref={(ref) => (this.messagesEndRef = ref)} />
          </div>
        ) : null}

        <div
          className="card-footer pt-4 shadow-sm"
          id={"kt_chat_messenger_footer"}
          style={{
            border: "1px solid gray",
            borderRadius: 15,
            marginTop: 10,
            flexShrink: 0,
            opacity: isLoading ? 0.5 : 1,
            pointerEvents: isLoading ? "none" : "all",
            position: "relative",
            maxHeight: "300px",
            overflowY: "auto",
          }}
        >
          <button
            id={voiceMessageButtonId}
            onClick={this.handleOnRecord}
            className={`btn btn-sm btn-icon btn-active-light-$
      {this.state.recording ? "danger" : "primary"} me-1`}
            disabled={!isAIReady}
            style={{ position: "absolute", right: "28px", zIndex: "9" }}
          >
            {this.state.recording ? (
              <i
                className="fa fa-stop"
                style={{ fontSize: "1.5rem", paddingRight: 0 }}
              ></i>
            ) : (
              <i
                className="fa fa-microphone"
                style={{ fontSize: "1.5rem", paddingRight: 0 }}
              ></i>
            )}
          </button>

          <MentionsInput
            id={mentionInputId}
            rows={1}
            className="form-control form-control-flush mb-3"
            data-kt-element="input"
            placeholder={messagePlaceholderText}
            value={message}
            onKeyDown={this.onEnterPress}
            onChange={this.handleMessageChange}
            maxLength={maxInputLength}
            style={{ width: "93%" }}
          >
            <Mention
              trigger="@"
              data={
                stores.userStore.isCurrentUserAdmin
                  ? stores.companyAppStore.companyApps
                      .filter(
                        (companyApp) =>
                          companyApp.status === CompanyAppStatus.Connected
                      )
                      .map((companyApp) => {
                        return {
                          id: companyApp.id,
                          display: `@${companyApp.app.name.replaceAll(
                            " ",
                            ""
                          )}`,
                        };
                      })
                  : filteredCompanyApps
                      .filter(
                        (companyApp) =>
                          companyApp.status === CompanyAppStatus.Connected
                      )
                      .map((companyApp) => {
                        return {
                          id: companyApp.id,
                          display: `@${companyApp.app.name.replaceAll(
                            " ",
                            ""
                          )}`,
                        };
                      })
              }
              renderSuggestion={this.renderSuggestionItem}
              appendSpaceOnAdd={true}
            />
          </MentionsInput>

          <div className="d-flex flex-stack">
            <div className="d-flex align-items-center">
              <button
                id={downloadButtonId}
                onClick={this.handleShareClick}
                className="btn btn-sm btn-icon btn-active-light-primary"
                type="button"
                data-bs-toggle="tooltip"
                title="Coming soon"
              >
                <i className="bi bi-download fs-2"></i>
              </button>

              <button
                id={webSearchButtonId}
                className="btn btn-sm btn-icon btn-active-light-primary"
                aria-pressed="false"
                aria-label="Web Search"
                onClick={async () => {
                  const canUseWebSearch =
                    await stores.userStore.checkSubscribedFeatureType(
                      FeatureType.WebSearch
                    );
                  if (!canUseWebSearch) {
                    toast.error(i18n.ToastMessages.webSearchError, {
                      position: "top-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeButton: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  } else {
                    this.setState((prevState) => {
                      const newState = !prevState.isWebSearchActive;
                      localStorage.setItem(
                        "isWebSearchActive",
                        newState.toString()
                      );
                      return { isWebSearchActive: newState };
                    });
                  }
                }}
                style={{
                  width: this.state.isWebSearchActive ? "100px" : "40px",
                  transition: "width 0.3s ease-in-out",
                  color: this.state.isWebSearchActive ? "#04c8c8" : "#99a1b7",
                  backgroundColor: this.state.isWebSearchActive
                    ? "#dcfdfd"
                    : "#fff",

                  border: "none",
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* SVG Icon */}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM11.9851 4.00291C11.9933 4.00046 11.9982 4.00006 11.9996 4C12.001 4.00006 12.0067 4.00046 12.0149 4.00291C12.0256 4.00615 12.047 4.01416 12.079 4.03356C12.2092 4.11248 12.4258 4.32444 12.675 4.77696C12.9161 5.21453 13.1479 5.8046 13.3486 6.53263C13.6852 7.75315 13.9156 9.29169 13.981 11H10.019C10.0844 9.29169 10.3148 7.75315 10.6514 6.53263C10.8521 5.8046 11.0839 5.21453 11.325 4.77696C11.5742 4.32444 11.7908 4.11248 11.921 4.03356C11.953 4.01416 11.9744 4.00615 11.9851 4.00291ZM8.01766 11C8.08396 9.13314 8.33431 7.41167 8.72334 6.00094C8.87366 5.45584 9.04762 4.94639 9.24523 4.48694C6.48462 5.49946 4.43722 7.9901 4.06189 11H8.01766ZM4.06189 13H8.01766C8.09487 15.1737 8.42177 17.1555 8.93 18.6802C9.02641 18.9694 9.13134 19.2483 9.24522 19.5131C6.48461 18.5005 4.43722 16.0099 4.06189 13ZM10.019 13H13.981C13.9045 14.9972 13.6027 16.7574 13.1726 18.0477C12.9206 18.8038 12.6425 19.3436 12.3823 19.6737C12.2545 19.8359 12.1506 19.9225 12.0814 19.9649C12.0485 19.9852 12.0264 19.9935 12.0153 19.9969C12.0049 20.0001 11.9999 20 11.9999 20C11.9999 20 11.9948 20 11.9847 19.9969C11.9736 19.9935 11.9515 19.9852 11.9186 19.9649C11.8494 19.9225 11.7455 19.8359 11.6177 19.6737C11.3575 19.3436 11.0794 18.8038 10.8274 18.0477C10.3973 16.7574 10.0955 14.9972 10.019 13ZM15.9823 13C15.9051 15.1737 15.5782 17.1555 15.07 18.6802C14.9736 18.9694 14.8687 19.2483 14.7548 19.5131C17.5154 18.5005 19.5628 16.0099 19.9381 13H15.9823ZM19.9381 11C19.5628 7.99009 17.5154 5.49946 14.7548 4.48694C14.9524 4.94639 15.1263 5.45584 15.2767 6.00094C15.6657 7.41167 15.916 9.13314 15.9823 11H19.9381Z"
                    fill="currentColor"
                  ></path>
                </svg>

                {/* "Search" Text */}
                {this.state.isWebSearchActive && (
                  <span
                    style={{
                      marginLeft: "5px",
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Search
                  </span>
                )}
              </button>

              {!stores.companyStorageStore.selectedCompanyStorage &&
              !isElectron ? (
                <>
                  <Link to="/storages" className="btn btn-sm btn-light-warning">
                    <span className="fs-6 ms-2">
                      Please connect your storage
                    </span>
                  </Link>
                </>
              ) : stores.companyAppStore.companyApps.length === 0 &&
                !isElectron ? (
                <>
                  <Link
                    to="/connections"
                    className="btn btn-sm btn-light-warning"
                  >
                    <span className="fs-6 ms-2">Please connect app</span>
                  </Link>
                </>
              ) : null}
            </div>
            <div>
              {!isAIReady && !stores.companyAvatarStore.isAvatarLoading ? (
                <button
                  className={`btn ${
                    !isAIReady ? "bg-gray-300 btn-btn-bg-light" : "btn-primary"
                  }`}
                  type="button"
                  data-kt-element="send"
                  onClick={this.handleStopChat}
                  disabled={isAIReady}
                  style={{
                    backgroundColor: !isAIReady ? "#f1f1f2" : undefined,
                    borderColor: !isAIReady ? "#f1f1f2" : undefined,
                  }}
                >
                  {isAIReady ? "Send" : "Stop"}
                </button>
              ) : (
                <button
                  disabled={
                    !isAIReady ||
                    (!selectedCompanyModel &&
                      !selectedCompanyAvatar &&
                      !canUseTheChief) ||
                    (selectedCompanyModel &&
                      this.state.selectedCompanyAppIds.length === 0)
                  }
                  className="btn btn-primary"
                  type="button"
                  data-kt-element="send"
                  onClick={this.sendMessage}
                >
                  Send
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
