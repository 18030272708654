import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import i18n from "../../../../i18n";

interface DeleteAccountModalProps {
  show: boolean;
  onClose: () => void;
  onDelete: () => Promise<void>;
  loading: boolean;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({
  show,
  onClose,
  onDelete,
  loading,
}) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{i18n.ToastMessages.confirmDeletion}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{i18n.ToastMessages.deleteAccountConfirmation}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {i18n.common.cancel}
        </Button>
        <Button variant="danger" onClick={onDelete} disabled={loading}>
          {loading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            i18n.common.delete
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteAccountModal;
